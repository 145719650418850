import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import jwtDecode from 'jwt-decode';

// sample page routing
const Dashboard = Loadable(lazy(() => import('views/dashboard/Default')));
const Statistics = Loadable(lazy(() => import('views/statistics')));
const Admins = Loadable(lazy(() => import('views/admins')));
const Users = Loadable(lazy(() => import('views/users')));
const Surveys = Loadable(lazy(() => import('views/surveys')));
const WorkshopTypes = Loadable(lazy(() => import('views/workshopTypes')));
const Organisation = Loadable(lazy(() => import('views/organisation')));
const SurveysList = Loadable(lazy(() => import('views/statistics/surveys')));
const QuestionsList = Loadable(lazy(() => import('views/statistics/questions')));
const CompareQuestionsList = Loadable(lazy(() => import('views/statistics/CompareQuestions')));

const renderRoutes = () => {
  const token = localStorage.getItem('unicef-token');
  if (token) {
    const { role } = jwtDecode(token);
    let children = [];
    if (role === 1) {
      children = [
        {
          path: '/',
          element: <Dashboard />,
        },
        {
          path: '/statistics',
          element: <Statistics />,
        },
        {
          path: '/users/admins',
          element: <Admins />,
        },
        {
          path: '/users/organisations',
          element: <Users />,
        },
        {
          path: '/workshopTypes',
          element: <WorkshopTypes />,
        },
        {
          path: '/workshops',
          element: <Surveys />,
        },
        {
          path: '/statistics/surveys/:id',
          element: <SurveysList />,
        },
        {
          path: '/statistics/surveys/:id/questions',
          element: <QuestionsList />,
        },
        {
          path: '/statistics/surveys/:id/compareQuestions',
          element: <CompareQuestionsList />,
        },
      ];
    } else if (role === 2) {
      children = [
        {
          path: '/',
          element: <Organisation />,
        },
        {
          path: '/statistics',
          element: <Statistics />,
        },
        {
          path: '/workshopTypes',
          element: <WorkshopTypes />,
        },
        {
          path: '/workshops',
          element: <Surveys />,
        },
        {
          path: '/statistics/surveys/:id',
          element: <SurveysList />,
        },
        {
          path: '/statistics/surveys/:id/questions',
          element: <QuestionsList />,
        },
      ];
    }
    return children;
  }
};

const MainRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: renderRoutes(),
};

export default MainRoutes;
