import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase, Avatar, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

// project imports
import config from 'config';
import Logo from 'ui-component/Logo';
import UnicefLogo from 'assets/images/logo/unicef-long.svg';
// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => {
  return (
    <ButtonBase disableRipple component={Link} to={config.defaultPath}>
      <Grid container spacing={3}>
        <Grid item sx={{ mt: 1 }}>
          <Avatar
            src={UnicefLogo}
            size="lg"
            variant="square"
            sx={{
              width: '170px',
              background: 'transparent',
              height: '100%',
            }}
          />
        </Grid>
      </Grid>
    </ButtonBase>
  );
};

export default LogoSection;
