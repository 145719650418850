// Imports: local files.
import ApiClient from '../../services/ApiClient';
import { showSnackbar, dispatchLoading, dispatchSuccess, dispatchError, getAxiosError } from '../../utils/redux';

// Name of property to be used in reducer data.
const answersName = 'answers';
const answerName = 'answer';

// Actions Types: Get All Answers
export const GET_ANSWERS_START = 'GET_ANSWERS_START';
export const GET_ANSWERS_SUCCESS = 'GET_ANSWERS_SUCCESS';
export const GET_ANSWERS_FAILED = 'GET_ANSWERS_FAILED';
export const GET_ANSWERS_RESET = 'GET_ANSWERS_RESET';

// Actions Types: Get All Answers By Unique Answer Id
export const GET_ANSWERS_BY_UNIQUE_ID_START = 'GET_ANSWERS_BY_UNIQUE_ID_START';
export const GET_ANSWERS_BY_UNIQUE_ID_SUCCESS = 'GET_ANSWERS_BY_UNIQUE_ID_SUCCESS';
export const GET_ANSWERS_BY_UNIQUE_ID_FAILED = 'GET_ANSWERS_BY_UNIQUE_ID_FAILED';
export const GET_ANSWERS_BY_UNIQUE_ID_RESET = 'GET_ANSWERS_BY_UNIQUE_ID_RESET';

// Actions Types: Get One Answer.
export const GET_ANSWER_START = 'GET_ANSWER_START';
export const GET_ANSWER_SUCCESS = 'GET_ANSWER_SUCCESS';
export const GET_ANSWER_FAILED = 'GET_ANSWER_FAILED';
export const GET_ANSWER_RESET = 'GET_ANSWER_RESET';

// Actions Types: Create Answer.
export const CREATE_ANSWER_START = 'CREATE_ANSWER_START';
export const CREATE_ANSWER_SUCCESS = 'CREATE_ANSWER_SUCCESS';
export const CREATE_ANSWER_FAILED = 'CREATE_ANSWER_FAILED';
export const CREATE_ANSWER_RESET = 'CREATE_ANSWER_RESET';

// Actions Types: Create Member Manually.
export const CREATE_MEMBER_MANUALLY_START = 'CREATE_MEMBER_MANUALLY_START';
export const CREATE_MEMBER_MANUALLY_SUCCESS = 'CREATE_MEMBER_MANUALLY_SUCCESS';
export const CREATE_MEMBER_MANUALLY_FAILED = 'CREATE_MEMBER_MANUALLY_FAILED';
export const CREATE_MEMBER_MANUALLY_RESET = 'CREATE_MEMBER_MANUALLY_RESET';

// Actions Types: Create Application.
export const CREATE_APPLICATION_START = 'CREATE_APPLICATION_START';
export const CREATE_APPLICATION_SUCCESS = 'CREATE_APPLICATION_SUCCESS';
export const CREATE_APPLICATION_FAILED = 'CREATE_APPLICATION_FAILED';
export const CREATE_APPLICATION_RESET = 'CREATE_APPLICATION_RESET';

// Actions Types: Update Answer.
export const UPDATE_ANSWER_START = 'UPDATE_ANSWER_START';
export const UPDATE_ANSWER_SUCCESS = 'UPDATE_ANSWER_SUCCESS';
export const UPDATE_ANSWER_FAILED = 'UPDATE_ANSWER_FAILED';
export const UPDATE_ANSWER_RESET = 'UPDATE_ANSWER_RESET';

// Actions Types: Delete Answer
export const DELETE_ANSWER_START = 'DELETE_ANSWER_START';
export const DELETE_ANSWER_SUCCESS = 'DELETE_ANSWER_SUCCESS';
export const DELETE_ANSWER_FAILED = 'DELETE_ANSWER_FAILED';
export const DELETE_ANSWER_RESET = 'DELETE_ANSWER_RESET';

// Action Creators: Get Answers.
const getAnswersStart = (payload) => ({ type: GET_ANSWERS_START, payload });
const getAnswersSuccess = (payload) => ({ type: GET_ANSWERS_SUCCESS, payload });
const getAnswersFailed = (payload) => ({ type: GET_ANSWERS_FAILED, payload });
const getAnswersReset = () => ({ type: GET_ANSWERS_RESET });

// Action Creators: Get Answers By Unique Id.
const getAnswersByUniqueIdStart = (payload) => ({ type: GET_ANSWERS_BY_UNIQUE_ID_START, payload });
const getAnswersByUniqueIdSuccess = (payload) => ({ type: GET_ANSWERS_BY_UNIQUE_ID_SUCCESS, payload });
const getAnswersByUniqueIdFailed = (payload) => ({ type: GET_ANSWERS_BY_UNIQUE_ID_FAILED, payload });
const getAnswersByUniqueIdReset = () => ({ type: GET_ANSWERS_BY_UNIQUE_ID_RESET });

// Action Creators: Get One Answer.
const getAnswerStart = (payload) => ({ type: GET_ANSWER_START, payload });
const getAnswerSuccess = (payload) => ({ type: GET_ANSWER_SUCCESS, payload });
const getAnswerFailed = (payload) => ({ type: GET_ANSWER_FAILED, payload });
const getAnswerReset = () => ({ type: GET_ANSWER_RESET });

// Action Creators: Create Answer.
const createAnswerStart = (payload) => ({ type: CREATE_ANSWER_START, payload });
const createAnswerSuccess = (payload) => ({ type: CREATE_ANSWER_SUCCESS, payload });
const createAnswerFailed = (payload) => ({ type: CREATE_ANSWER_FAILED, payload });
const createAnswerReset = () => ({ type: CREATE_ANSWER_RESET });

// Action Creators: Create Member Manually.
const createMemberManuallyStart = (payload) => ({ type: CREATE_MEMBER_MANUALLY_START, payload });
const createMemberManuallySuccess = (payload) => ({ type: CREATE_MEMBER_MANUALLY_SUCCESS, payload });
const createMemberManuallyFailed = (payload) => ({ type: CREATE_MEMBER_MANUALLY_FAILED, payload });
const createMemberManuallyReset = () => ({ type: CREATE_MEMBER_MANUALLY_RESET });

// Action Creators: Create Application.
const createApplicationStart = (payload) => ({ type: CREATE_APPLICATION_START, payload });
const createApplicationSuccess = (payload) => ({ type: CREATE_APPLICATION_SUCCESS, payload });
const createApplicationFailed = (payload) => ({ type: CREATE_APPLICATION_FAILED, payload });
const createApplicationReset = () => ({ type: CREATE_APPLICATION_RESET });

// Action Creators: Update Answer.
const updateAnswerStart = (payload) => ({ type: UPDATE_ANSWER_START, payload });
const updateAnswerSuccess = (payload) => ({ type: UPDATE_ANSWER_SUCCESS, payload });
const updateAnswerFailed = (payload) => ({ type: UPDATE_ANSWER_FAILED, payload });
const updateAnswerReset = () => ({ type: UPDATE_ANSWER_RESET });

// Action Creators: Delete Workshop.
const deleteAnswerStart = (payload) => ({ type: DELETE_ANSWER_START, payload });
const deleteAnswerSuccess = (payload) => ({ type: DELETE_ANSWER_SUCCESS, payload });
const deleteAnswerFailed = (payload) => ({ type: DELETE_ANSWER_FAILED, payload });
const deleteAnswerReset = () => ({ type: DELETE_ANSWER_RESET });

// Actions: Get All Answers.
export const getAllAnswers = (payload, options = {}) => {
  const {
    pagination,
    page,
    limit,
    workshop,
    question,
    survey,
    application,
    uniqueAnswerId,
    uniqueAnswer,
    acceptedMember,
  } = payload;
  const {} = options;
  return async (dispatch) => {
    try {
      dispatchLoading(dispatch, getAnswersStart);
      const result = await ApiClient.get('/answers', {
        params: {
          pagination,
          page,
          limit,
          workshop,
          question,
          survey,
          application,
          uniqueAnswerId,
          uniqueAnswer,
          acceptedMember,
        },
      });
      if (result.data?.success) {
        const { answers } = result.data?.data;
        dispatchSuccess(dispatch, getAnswersSuccess, answers, answersName);
      } else {
        const errorMessage = result.data?.error || 'Something went wrong!';
        dispatchError(dispatch, getAnswersFailed, errorMessage);
        showSnackbar(dispatch, errorMessage, 'error');
      }
    } catch (error) {
      const errorMessage = getAxiosError(error);
      dispatchError(dispatch, getAnswersFailed, errorMessage);
      showSnackbar(dispatch, errorMessage, 'error');
    }
  };
};

// Actions: Clear All Answers.
export const clearAllAnswers = () => getAnswersReset();

// Actions: Get All Answers By Unique Answer Id.
export const getAllAnswersByUniqueId = (payload, options = {}) => {
  const { pagination, page, limit, workshop, survey, uniqueAnswerId } = payload;
  const {} = options;
  return async (dispatch) => {
    try {
      dispatchLoading(dispatch, getAnswersByUniqueIdStart);
      const result = await ApiClient.get('/answers', {
        params: { pagination, page, limit, workshop, survey, uniqueAnswerId },
      });
      if (result.data?.success) {
        const { answers } = result.data?.data;
        dispatchSuccess(dispatch, getAnswersByUniqueIdSuccess, answers, answersName);
      } else {
        const errorMessage = result.data?.error || 'Something went wrong!';
        dispatchError(dispatch, getAnswersByUniqueIdFailed, errorMessage);
        showSnackbar(dispatch, errorMessage, 'error');
      }
    } catch (error) {
      const errorMessage = getAxiosError(error);
      dispatchError(dispatch, getAnswersByUniqueIdFailed, errorMessage);
      showSnackbar(dispatch, errorMessage, 'error');
    }
  };
};

// Actions: Clear All Answers By Unique Id.
export const clearAllAnswersByUniqueId = () => getAnswersByUniqueIdReset();

// Actions: Get One Answer.
export const getOneAnswer = (payload, options = {}) => {
  const { answerId } = payload;
  const {} = options;
  return async (dispatch) => {
    try {
      dispatchLoading(dispatch, getAnswerStart);

      const result = await ApiClient.get(`/answers/${answerId}`);
      if (result.data?.success) {
        const { answer } = result.data?.data;
        dispatchSuccess(dispatch, getAnswerSuccess, answer, answerName);
      } else {
        const errorMessage = result.data?.error || 'Something went wrong!';
        dispatchError(dispatch, getAnswerFailed, errorMessage);
        showSnackbar(dispatch, errorMessage, 'error');
      }
    } catch (error) {
      const errorMessage = getAxiosError(error);
      dispatchError(dispatch, getAnswerFailed, errorMessage);
      showSnackbar(dispatch, errorMessage, 'error');
    }
  };
};

// Actions: Clear One Answer.
export const clearOneAnswer = () => getAnswerReset();

// Actions: Create New Answer.
export const createAnswer = (payload, options = {}) => {
  const { survey, answers, preSurvey } = payload;
  const { navigate, pathName, shouldRedirect } = options;
  return async (dispatch) => {
    try {
      dispatchLoading(dispatch, createAnswerStart);
      const result = await ApiClient.post(`/answers`, {
        answers,
        survey,
        preSurvey,
      });
      if (result.data?.success) {
        const { answer } = result.data?.data;
        dispatchSuccess(dispatch, createAnswerSuccess, answer, answerName);
        showSnackbar(dispatch, 'Answers submited successfully!', 'success');
        shouldRedirect && navigate(pathName, { state: { redirectPage: true, isApplication: false } });
      } else {
        const errorMessage = result.data?.error || 'Something went wrong!';
        dispatchError(dispatch, createAnswerFailed, errorMessage);
        showSnackbar(dispatch, errorMessage, 'error');
      }
    } catch (error) {
      const errorMessage = getAxiosError(error);
      dispatchError(dispatch, createAnswerFailed, errorMessage);
      showSnackbar(dispatch, errorMessage, 'error');
    }
  };
};

// Actions: Clear Created Answer.
export const clearCreatedAnswer = () => createAnswerReset();

// Actions: Create Member Manually.
export const createMemberManually = (payload, options = {}) => {
  const { workshop, survey, answers } = payload;
  const { navigate, pathName, shouldRedirect } = options;
  return async (dispatch) => {
    try {
      dispatchLoading(dispatch, createMemberManuallyStart);
      const result = await ApiClient.post(`/answers/manually`, {
        workshop,
        answers,
        survey,
      });
      if (result.data?.success) {
        const { data } = result.data;
        dispatchSuccess(dispatch, createMemberManuallySuccess, data, answerName);
        showSnackbar(dispatch, 'Member added successfully!', 'success');
        shouldRedirect && navigate(pathName, { state: { redirectPage: true, isApplication: false } });
      } else {
        const errorMessage = result.data?.error || 'Something went wrong!';
        dispatchError(dispatch, createMemberManuallyFailed, errorMessage);
        showSnackbar(dispatch, errorMessage, 'error');
      }
    } catch (error) {
      const errorMessage = getAxiosError(error);
      dispatchError(dispatch, createMemberManuallyFailed, errorMessage);
      showSnackbar(dispatch, errorMessage, 'error');
    }
  };
};

// Actions: Clear Created Answer.
export const clearCreatedMemberManually = () => createMemberManuallyReset();

// Actions: Update Answer.
export const updateAnswer = (payload, options = {}) => {
  const { answerId, question, survey, isUniqueAnswer, type, value } = payload;
  const {} = options;
  return async (dispatch) => {
    try {
      dispatchLoading(dispatch, updateAnswerStart);

      const result = await ApiClient.put(`/answers/${answerId}`, {
        question,
        survey,
        isUniqueAnswer,
        type,
        value,
      });
      if (result.data?.success) {
        const { answer } = result.data?.data;
        dispatchSuccess(dispatch, updateAnswerSuccess, answer, answerName);
        showSnackbar(dispatch, 'Workshop updated successfully!', 'success');
      } else {
        const errorMessage = result.data?.error || 'Something went wrong!';
        dispatchError(dispatch, updateAnswerFailed, errorMessage);
        showSnackbar(dispatch, errorMessage, 'error');
      }
    } catch (error) {
      const errorMessage = getAxiosError(error);
      dispatchError(dispatch, updateAnswerFailed, errorMessage);
      showSnackbar(dispatch, errorMessage, 'error');
    }
  };
};

// Actions: Clear Updated Answer.
export const clearUpdatedAnswer = () => updateAnswerReset();

// Actions: Delete One User.
export const deleteOneAnswer = (payload, options = {}) => {
  const { answerId } = payload;
  const {} = options;
  return async (dispatch) => {
    try {
      dispatchLoading(dispatch, deleteAnswerStart);

      const result = await ApiClient.delete(`/answers/${answerId}`);
      if (result.data?.success) {
        const { answer } = result.data?.data;
        dispatchSuccess(dispatch, deleteAnswerSuccess, answer, answerName);
        showSnackbar(dispatch, 'Workshop deleted successfully!', 'success');
      } else {
        const errorMessage = result.data?.error || 'Something went wrong!';
        dispatchError(dispatch, deleteAnswerFailed, errorMessage);
        showSnackbar(dispatch, errorMessage, 'error');
      }
    } catch (error) {
      const errorMessage = getAxiosError(error);
      dispatchError(dispatch, deleteAnswerFailed, errorMessage);
      showSnackbar(dispatch, errorMessage, 'error');
    }
  };
};

// Actions: Clear Deleted Answer.
export const clearDeletedAnswer = () => deleteAnswerReset();
