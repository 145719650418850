// Imports: local files.
import ApiClient from '../../services/ApiClient';
import { showSnackbar, dispatchLoading, dispatchSuccess, dispatchError, getAxiosError } from '../../utils/redux';

// Name of property to be used in reducer data.
const countsName = 'counts';
const usersName = 'users';
const workshopsName = 'workshops';

// Actions Types: COUNTS.
export const COUNTS_START = 'COUNTS_START';
export const COUNTS_SUCCESS = 'COUNTS_SUCCESS';
export const COUNTS_FAILED = 'COUNTS_FAILED';
export const COUNTS_RESET = 'COUNTS_RESET';

// Actions Types: Users by Role.
export const USERS_BY_ROLE_START = 'USERS_BY_ROLE_START';
export const USERS_BY_ROLE_SUCCESS = 'USERS_BY_ROLE_SUCCESS';
export const USERS_BY_ROLE_FAILED = 'USERS_BY_ROLE_FAILED';
export const USERS_BY_ROLE_RESET = 'USERS_BY_ROLE_RESET';

// Actions Types: Workshops by Type.
export const WORKSHOPS_BY_TYPE_START = 'WORKSHOPS_BY_TYPE_START';
export const WORKSHOPS_BY_TYPE_SUCCESS = 'WORKSHOPS_BY_TYPE_SUCCESS';
export const WORKSHOPS_BY_TYPE_FAILED = 'WORKSHOPS_BY_TYPE_FAILED';
export const WORKSHOPS_BY_TYPE_RESET = 'WORKSHOPS_BY_TYPE_RESET';

// Actions Types: Question Reports.
export const QUESTION_REPORTS_START = 'QUESTION_REPORTS_START';
export const QUESTION_REPORTS_SUCCESS = 'QUESTION_REPORTS_SUCCESS';
export const QUESTION_REPORTS_FAILED = 'QUESTION_REPORTS_FAILED';
export const QUESTION_REPORTS_RESET = 'QUESTION_REPORTS_RESET';

// Actions Types: Checkbox Reports.
export const CHECKBOX_REPORTS_START = 'CHECKBOX_REPORTS_START';
export const CHECKBOX_REPORTS_SUCCESS = 'CHECKBOX_REPORTS_SUCCESS';
export const CHECKBOX_REPORTS_FAILED = 'CHECKBOX_REPORTS_FAILED';
export const CHECKBOX_REPORTS_RESET = 'CHECKBOX_REPORTS_RESET';

// Actions Types: Matrix Reports.
export const MATRIX_REPORTS_START = 'MATRIX_REPORTS_START';
export const MATRIX_REPORTS_SUCCESS = 'MATRIX_REPORTS_SUCCESS';
export const MATRIX_REPORTS_FAILED = 'MATRIX_REPORTS_FAILED';
export const MATRIX_REPORTS_RESET = 'MATRIX_REPORTS_RESET';

// Action Creators: Counts.
const countsStart = (payload) => ({ type: COUNTS_START, payload });
const countsSuccess = (payload) => ({ type: COUNTS_SUCCESS, payload });
const countsFailed = (payload) => ({ type: COUNTS_FAILED, payload });
const countsReset = () => ({ type: COUNTS_RESET });

// Action Creators: Users By Role.
const usersByRoleStart = (payload) => ({ type: USERS_BY_ROLE_START, payload });
const usersByRoleSuccess = (payload) => ({ type: USERS_BY_ROLE_SUCCESS, payload });
const usersByRoleFailed = (payload) => ({ type: USERS_BY_ROLE_FAILED, payload });
const usersByRoleReset = () => ({ type: USERS_BY_ROLE_RESET });

// Action Creators: Workshops By Type.
const workshopsByTypeStart = (payload) => ({ type: WORKSHOPS_BY_TYPE_START, payload });
const workshopsByTypeSuccess = (payload) => ({ type: WORKSHOPS_BY_TYPE_SUCCESS, payload });
const workshopsByTypeFailed = (payload) => ({ type: WORKSHOPS_BY_TYPE_FAILED, payload });
const workshopsByTypeReset = () => ({ type: WORKSHOPS_BY_TYPE_RESET });

// Action Creators: Question Reports.
const questionReportsStart = (payload) => ({ type: QUESTION_REPORTS_START, payload });
const questionReportsSuccess = (payload) => ({ type: QUESTION_REPORTS_SUCCESS, payload });
const questionReportsFailed = (payload) => ({ type: QUESTION_REPORTS_FAILED, payload });
const questionReportsReset = () => ({ type: QUESTION_REPORTS_RESET });

// Action Creators: Checkbox Reports.
const checkboxReportsStart = (payload) => ({ type: CHECKBOX_REPORTS_START, payload });
const checkboxReportsSuccess = (payload) => ({ type: CHECKBOX_REPORTS_SUCCESS, payload });
const checkboxReportsFailed = (payload) => ({ type: CHECKBOX_REPORTS_FAILED, payload });
const checkboxReportsReset = () => ({ type: CHECKBOX_REPORTS_RESET });

// Action Creators: Matrix Reports.
const matrixReportsStart = (payload) => ({ type: MATRIX_REPORTS_START, payload });
const matrixReportsSuccess = (payload) => ({ type: MATRIX_REPORTS_SUCCESS, payload });
const matrixReportsFailed = (payload) => ({ type: MATRIX_REPORTS_FAILED, payload });
const matrixReportsReset = () => ({ type: MATRIX_REPORTS_RESET });

// Actions: Get All Counts.
export const counts = (payload, options = {}) => {
  const { startDate, endDate } = payload;
  const {} = options;
  const query = {};
  //   if (startDate) query['startDate'] = startDate;
  //   if (endDate) query['endDate'] = endDate;
  return async (dispatch) => {
    try {
      dispatchLoading(dispatch, countsStart);

      const result = await ApiClient.post('/reports/counts', query);
      if (result.data?.success) {
        const { counts } = result.data?.data;
        dispatchSuccess(dispatch, countsSuccess, counts, countsName);
      } else {
        const errorMessage = result.data?.error || 'Something went wrong!';
        dispatchError(dispatch, countsFailed, errorMessage);
        showSnackbar(dispatch, errorMessage, 'error');
      }
    } catch (error) {
      const errorMessage = getAxiosError(error);
      dispatchError(dispatch, countsFailed, errorMessage);
      showSnackbar(dispatch, errorMessage, 'error');
    }
  };
};

// Actions: Clear Counts.
export const clearCounts = () => countsReset();

// Actions: Users By Role.
export const usersByRole = (payload, options = {}) => {
  const { userStartDate, userEndDate } = payload;
  const {} = options;
  return async (dispatch) => {
    try {
      dispatchLoading(dispatch, usersByRoleStart);

      const result = await ApiClient.post(`/reports/users`, {
        startDate: userStartDate,
        endDate: userEndDate,
        type: 'BY_ROLE',
      });
      if (result.data?.success) {
        const { users } = result.data?.data;
        dispatchSuccess(dispatch, usersByRoleSuccess, users, usersName);
      } else {
        const errorMessage = result.data?.error || 'Something went wrong!';
        dispatchError(dispatch, usersByRoleFailed, errorMessage);
        showSnackbar(dispatch, errorMessage, 'error');
      }
    } catch (error) {
      const errorMessage = getAxiosError(error);
      dispatchError(dispatch, usersByRoleFailed, errorMessage);
      showSnackbar(dispatch, errorMessage, 'error');
    }
  };
};

// Actions: Clear Users By Role.
export const clearUsersByRole = () => usersByRoleReset();

// Actions: Users By Role.
export const workshopsByType = (payload, options = {}) => {
  const { startDate, endDate } = payload;
  const {} = options;
  return async (dispatch) => {
    try {
      dispatchLoading(dispatch, workshopsByTypeStart);

      const result = await ApiClient.post(`/reports/workshops`, { startDate, endDate, type: 'BY_TYPE' });
      if (result.data?.success) {
        const { workshopTypeCount } = result.data?.data;
        dispatchSuccess(dispatch, workshopsByTypeSuccess, workshopTypeCount, 'workshopTypeCount');
      } else {
        const errorMessage = result.data?.error || 'Something went wrong!';
        dispatchError(dispatch, workshopsByTypeFailed, errorMessage);
        showSnackbar(dispatch, errorMessage, 'error');
      }
    } catch (error) {
      const errorMessage = getAxiosError(error);
      dispatchError(dispatch, workshopsByTypeFailed, errorMessage);
      showSnackbar(dispatch, errorMessage, 'error');
    }
  };
};

// Actions: Clear Get All Survey Questions.
export const clearWorkshopsByType = () => workshopsByTypeReset();

// Actions: Question Reports.
export const questionReports = (payload, options = {}) => {
  const { startDate, endDate, workshopTypeId, title, type, workshop, organisation } = payload;
  const {} = options;
  return async (dispatch) => {
    try {
      dispatchLoading(dispatch, questionReportsStart);

      const result = await ApiClient.post(`/reports/questions`, { workshopTypeId, title, type });
      if (result.data?.success) {
        const { questionAnswers } = result.data?.data;
        dispatchSuccess(dispatch, questionReportsSuccess, questionAnswers, 'questionAnswers');
      } else {
        const errorMessage = result.data?.error || 'Something went wrong!';
        dispatchError(dispatch, questionReportsFailed, errorMessage);
        showSnackbar(dispatch, errorMessage, 'error');
      }
    } catch (error) {
      const errorMessage = getAxiosError(error);
      dispatchError(dispatch, questionReportsFailed, errorMessage);
      showSnackbar(dispatch, errorMessage, 'error');
    }
  };
};

// Actions: Clear Question Reports.
export const clearQuestionReports = () => questionReportsReset();

// Actions: Checkbox Reports.
export const checkboxReports = (payload, options = {}) => {
  const { startDate, endDate, workshopTypeId, title, type, workshop, organisation } = payload;
  const {} = options;
  return async (dispatch) => {
    try {
      dispatchLoading(dispatch, checkboxReportsStart);

      const result = await ApiClient.post(`/reports/checkbox`, { workshopTypeId, title, type });
      if (result.data?.success) {
        const { checkboxAnswers } = result.data?.data;
        dispatchSuccess(dispatch, checkboxReportsSuccess, checkboxAnswers, 'checkboxAnswers');
      } else {
        const errorMessage = result.data?.error || 'Something went wrong!';
        dispatchError(dispatch, checkboxReportsFailed, errorMessage);
        showSnackbar(dispatch, errorMessage, 'error');
      }
    } catch (error) {
      const errorMessage = getAxiosError(error);
      dispatchError(dispatch, checkboxReportsFailed, errorMessage);
      showSnackbar(dispatch, errorMessage, 'error');
    }
  };
};

// Actions: Clear Checkbox Reports.
export const clearCheckboxReports = () => checkboxReportsReset();

// Actions: Matrix Reports.
export const matrixReports = (payload, options = {}) => {
  const { startDate, endDate, workshopTypeId, title, type, workshop, organisation } = payload;
  const {} = options;
  return async (dispatch) => {
    try {
      dispatchLoading(dispatch, matrixReportsStart);

      const result = await ApiClient.post(`/reports/matrix`, { workshopTypeId, title, type });
      if (result.data?.success) {
        const { data } = result.data;
        dispatchSuccess(dispatch, matrixReportsSuccess, data, 'data');
      } else {
        const errorMessage = result.data?.error || 'Something went wrong!';
        dispatchError(dispatch, matrixReportsFailed, errorMessage);
        showSnackbar(dispatch, errorMessage, 'error');
      }
    } catch (error) {
      const errorMessage = getAxiosError(error);
      dispatchError(dispatch, matrixReportsFailed, errorMessage);
      showSnackbar(dispatch, errorMessage, 'error');
    }
  };
};

// Actions: Clear matrix Reports.
export const clearMatrixReports = () => matrixReportsReset();
