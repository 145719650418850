// project imports
import MinimalLayout from 'layout/MinimalLayout';
import { lazy } from 'react';
import Loadable from 'ui-component/Loadable';

const MaintenanceError = Loadable(lazy(() => import('views/pages/maintenance/Error')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: '*',
      element: <MaintenanceError />,
    },
  ],
};

export default AuthenticationRoutes;
