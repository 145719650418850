import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Avatar,
  ButtonBase,
  Drawer,
  Fab,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Tooltip,
} from '@mui/material';
import { IconChecks, IconSettings } from '@tabler/icons';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import SubCard from 'ui-component/cards/SubCard';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { MENU_TYPE, PRESET_COLORS } from 'store/actions';
import { gridSpacing } from 'store/reducers/constant';

// color import
import colors from 'assets/scss/_themes-vars.module.scss';
import theme1 from 'assets/scss/_theme1.module.scss';
import theme2 from 'assets/scss/_theme2.module.scss';
import theme3 from 'assets/scss/_theme3.module.scss';
import theme4 from 'assets/scss/_theme4.module.scss';
import theme5 from 'assets/scss/_theme5.module.scss';
import theme6 from 'assets/scss/_theme6.module.scss';

// concat 'px'
function valueText(value) {
  return `${value}px`;
}

const PresetColor = ({ color, presetColor, setPresetColor }) => (
  <Grid item>
    <ButtonBase sx={{ borderRadius: '12px' }} onClick={() => setPresetColor(color.id)}>
      <Avatar
        variant="rounded"
        color="inherit"
        sx={{
          background: `linear-gradient(135deg, ${color.primary} 50%, ${color.secondary} 50%)`,
          opacity: presetColor === color.id ? 0.6 : 1,
        }}
      >
        {presetColor === color.id && <IconChecks color="#fff" />}
      </Avatar>
    </ButtonBase>
  </Grid>
);

PresetColor.propTypes = {
  color: PropTypes.shape({
    id: PropTypes.string,
    primary: PropTypes.string,
    secondary: PropTypes.string,
  }),
  presetColor: PropTypes.string,
  setPresetColor: PropTypes.func,
};

// ==============================|| LIVE CUSTOMIZATION ||============================== //

const Customization = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const customization = useSelector((state) => state.customization);

  const themeType = localStorage.getItem('theme');

  // drawer on/off
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen(!open);
  };

  // state - layout type
  const [navType, setNavType] = useState(themeType !== null ? themeType : customization.navType);
  useEffect(() => {
    dispatch({ type: MENU_TYPE, navType });
    localStorage.setItem('theme', navType);
  }, [dispatch, navType]);

  // state - preset color
  const [presetColor, setPresetColor] = useState(customization.presetColor);
  useEffect(() => {
    dispatch({ type: PRESET_COLORS, presetColor });
  }, [dispatch, presetColor]);

  const colorOptions = [
    {
      id: 'default',
      primary: theme.palette.mode === 'dark' ? colors.darkPrimaryMain : colors.primaryMain,
      secondary: theme.palette.mode === 'dark' ? colors.darkSecondaryMain : colors.secondaryMain,
    },
    {
      id: 'theme1',
      primary: theme.palette.mode === 'dark' ? theme1.darkPrimaryMain : theme1.primaryMain,
      secondary: theme.palette.mode === 'dark' ? theme1.darkSecondaryMain : theme1.secondaryMain,
    },
    {
      id: 'theme2',
      primary: theme.palette.mode === 'dark' ? theme2.darkPrimaryMain : theme2.primaryMain,
      secondary: theme.palette.mode === 'dark' ? theme2.darkSecondaryMain : theme2.secondaryMain,
    },
    {
      id: 'theme3',
      primary: theme.palette.mode === 'dark' ? theme3.darkPrimaryMain : theme3.primaryMain,
      secondary: theme.palette.mode === 'dark' ? theme3.darkSecondaryMain : theme3.secondaryMain,
    },
    {
      id: 'theme4',
      primary: theme.palette.mode === 'dark' ? theme4.darkPrimaryMain : theme4.primaryMain,
      secondary: theme.palette.mode === 'dark' ? theme4.darkSecondaryMain : theme4.secondaryMain,
    },
    {
      id: 'theme5',
      primary: theme.palette.mode === 'dark' ? theme5.darkPrimaryMain : theme5.primaryMain,
      secondary: theme.palette.mode === 'dark' ? theme5.darkSecondaryMain : theme5.secondaryMain,
    },
    {
      id: 'theme6',
      primary: theme.palette.mode === 'dark' ? theme6.darkPrimaryMain : theme6.primaryMain,
      secondary: theme.palette.mode === 'dark' ? theme6.darkSecondaryMain : theme6.secondaryMain,
    },
  ];

  return (
    <>
      {/* toggle button */}
      <Tooltip title="Live Customize">
        <Fab
          component="div"
          onClick={handleToggle}
          size="medium"
          variant="circular"
          color="secondary"
          sx={{
            borderRadius: 0,
            borderTopLeftRadius: '50%',
            borderBottomLeftRadius: '50%',
            borderTopRightRadius: '50%',
            borderBottomRightRadius: '4px',
            top: '25%',
            position: 'fixed',
            right: 10,
            zIndex: theme.zIndex.speedDial,
            boxShadow: theme.customShadows.secondary,
          }}
        >
          <AnimateButton type="rotate">
            <IconButton color="inherit" size="large" disableRipple>
              <IconSettings />
            </IconButton>
          </AnimateButton>
        </Fab>
      </Tooltip>

      <Drawer
        anchor="right"
        onClose={handleToggle}
        open={open}
        PaperProps={{
          sx: {
            width: 280,
          },
        }}
      >
        <PerfectScrollbar component="div">
          <Grid container spacing={gridSpacing} sx={{ p: 3 }}>
            <Grid item xs={12}>
              {/* layout type */}
              <SubCard title="Layout">
                <FormControl component="fieldset">
                  <FormLabel component="legend">Mode</FormLabel>
                  <RadioGroup
                    row
                    aria-label="layout"
                    value={navType}
                    onChange={(e) => setNavType(e.target.value)}
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value="light"
                      control={<Radio />}
                      label="Light"
                      sx={{
                        '& .MuiSvgIcon-root': { fontSize: 28 },
                        '& .MuiFormControlLabel-label': { color: theme.palette.grey[900] },
                      }}
                    />
                    <FormControlLabel
                      value="dark"
                      control={<Radio />}
                      label="Dark"
                      sx={{
                        '& .MuiSvgIcon-root': { fontSize: 28 },
                        '& .MuiFormControlLabel-label': { color: theme.palette.grey[900] },
                      }}
                    />
                  </RadioGroup>
                </FormControl>
              </SubCard>
            </Grid>
            <Grid item xs={12}>
              {/* Theme Preset Color */}
              <SubCard title="Preset Color">
                <Grid item container spacing={2} alignItems="center">
                  {colorOptions.map((color, index) => (
                    <PresetColor key={index} color={color} presetColor={presetColor} setPresetColor={setPresetColor} />
                  ))}
                </Grid>
              </SubCard>
            </Grid>
          </Grid>
        </PerfectScrollbar>
      </Drawer>
    </>
  );
};

export default Customization;
