// Imports: local files.
import {
  GET_WORKSHOP_TYPES_START,
  GET_WORKSHOP_TYPES_SUCCESS,
  GET_WORKSHOP_TYPES_FAILED,
  GET_WORKSHOP_TYPES_RESET,
  GET_WORKSHOP_TYPE_START,
  GET_WORKSHOP_TYPE_SUCCESS,
  GET_WORKSHOP_TYPE_FAILED,
  GET_WORKSHOP_TYPE_RESET,
  CREATE_WORKSHOP_TYPE_START,
  CREATE_WORKSHOP_TYPE_SUCCESS,
  CREATE_WORKSHOP_TYPE_FAILED,
  CREATE_WORKSHOP_TYPE_RESET,
  UPDATE_WORKSHOP_TYPE_START,
  UPDATE_WORKSHOP_TYPE_SUCCESS,
  UPDATE_WORKSHOP_TYPE_FAILED,
  UPDATE_WORKSHOP_TYPE_RESET,
  DELETE_WORKSHOP_TYPE_START,
  DELETE_WORKSHOP_TYPE_SUCCESS,
  DELETE_WORKSHOP_TYPE_FAILED,
  DELETE_WORKSHOP_TYPE_RESET,
} from '../actions';

const initialLoadingState = {
  loading: false,
  success: false,
  data: null,
  error: false,
  errorMessage: null,
};
const initialState = {
  getAll: initialLoadingState,
  getOne: initialLoadingState,
  create: initialLoadingState,
  update: initialLoadingState,
  deleteOne: initialLoadingState,
};

const workshopTypesReducer = (state = initialState, action) => {
  switch (action.type) {
    /**
     * =======================================================================
     * Get All Workshop Types
     * =======================================================================
     */
    case GET_WORKSHOP_TYPES_START:
      return { ...state, getAll: { ...action.payload } };
    case GET_WORKSHOP_TYPES_SUCCESS:
      return { ...state, getAll: { ...action.payload } };
    case GET_WORKSHOP_TYPES_FAILED:
      return { ...state, getAll: { ...action.payload } };
    case GET_WORKSHOP_TYPES_RESET:
      return { ...state, getAll: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Get One Workshop Type
     * =======================================================================
     */
    case GET_WORKSHOP_TYPE_START:
      return { ...state, getOne: { ...action.payload } };
    case GET_WORKSHOP_TYPE_SUCCESS:
      return { ...state, getOne: { ...action.payload } };
    case GET_WORKSHOP_TYPE_FAILED:
      return { ...state, getOne: { ...action.payload } };
    case GET_WORKSHOP_TYPE_RESET:
      return { ...state, getOne: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Create One Workshop Type
     * =======================================================================
     */
    case CREATE_WORKSHOP_TYPE_START:
      return { ...state, create: { ...action.payload } };
    case CREATE_WORKSHOP_TYPE_SUCCESS:
      return { ...state, create: { ...action.payload } };
    case CREATE_WORKSHOP_TYPE_FAILED:
      return { ...state, create: { ...action.payload } };
    case CREATE_WORKSHOP_TYPE_RESET:
      return { ...state, create: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Create One Workshop Type
     * =======================================================================
     */
    case UPDATE_WORKSHOP_TYPE_START:
      return { ...state, update: { ...action.payload } };
    case UPDATE_WORKSHOP_TYPE_SUCCESS:
      return { ...state, update: { ...action.payload } };
    case UPDATE_WORKSHOP_TYPE_FAILED:
      return { ...state, update: { ...action.payload } };
    case UPDATE_WORKSHOP_TYPE_RESET:
      return { ...state, update: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Delete One Workshop Type
     * =======================================================================
     */
    case DELETE_WORKSHOP_TYPE_START:
      return { ...state, deleteOne: { ...action.payload } };
    case DELETE_WORKSHOP_TYPE_SUCCESS:
      return { ...state, deleteOne: { ...action.payload } };
    case DELETE_WORKSHOP_TYPE_FAILED:
      return { ...state, deleteOne: { ...action.payload } };
    case DELETE_WORKSHOP_TYPE_RESET:
      return { ...state, deleteOne: { ...initialLoadingState } };
    default:
      return state;
  }
};

export default workshopTypesReducer;
