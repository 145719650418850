// Imports: local files.
import {
  GET_USERS_START,
  GET_USERS_SUCCESS,
  GET_USERS_FAILED,
  GET_USERS_RESET,
  GET_USER_START,
  GET_USER_SUCCESS,
  GET_USER_FAILED,
  GET_USER_RESET,
  CREATE_USER_START,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILED,
  CREATE_USER_RESET,
  UPDATE_USER_START,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILED,
  UPDATE_USER_RESET,
  DELETE_USER_START,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILED,
  DELETE_USER_RESET,
} from '../actions';

const initialLoadingState = {
  loading: false,
  success: false,
  data: null,
  error: false,
  errorMessage: null,
};
const initialState = {
  getAll: initialLoadingState,
  getOne: initialLoadingState,
  create: initialLoadingState,
  update: initialLoadingState,
  deleteOne: initialLoadingState,
};

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    /**
     * =======================================================================
     * Get All Users
     * =======================================================================
     */
    case GET_USERS_START:
      return { ...state, getAll: { ...action.payload } };
    case GET_USERS_SUCCESS:
      return { ...state, getAll: { ...action.payload } };
    case GET_USERS_FAILED:
      return { ...state, getAll: { ...action.payload } };
    case GET_USERS_RESET:
      return { ...state, getAll: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Get One User
     * =======================================================================
     */
    case GET_USER_START:
      return { ...state, getOne: { ...action.payload } };
    case GET_USER_SUCCESS:
      return { ...state, getOne: { ...action.payload } };
    case GET_USER_FAILED:
      return { ...state, getOne: { ...action.payload } };
    case GET_USER_RESET:
      return { ...state, getOne: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Create One User
     * =======================================================================
     */
    case CREATE_USER_START:
      return { ...state, create: { ...action.payload } };
    case CREATE_USER_SUCCESS:
      return { ...state, create: { ...action.payload } };
    case CREATE_USER_FAILED:
      return { ...state, create: { ...action.payload } };
    case CREATE_USER_RESET:
      return { ...state, create: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Create One User
     * =======================================================================
     */
    case UPDATE_USER_START:
      return { ...state, update: { ...action.payload } };
    case UPDATE_USER_SUCCESS:
      return { ...state, update: { ...action.payload } };
    case UPDATE_USER_FAILED:
      return { ...state, update: { ...action.payload } };
    case UPDATE_USER_RESET:
      return { ...state, update: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Delete One User
     * =======================================================================
     */
    case DELETE_USER_START:
      return { ...state, deleteOne: { ...action.payload } };
    case DELETE_USER_SUCCESS:
      return { ...state, deleteOne: { ...action.payload } };
    case DELETE_USER_FAILED:
      return { ...state, deleteOne: { ...action.payload } };
    case DELETE_USER_RESET:
      return { ...state, deleteOne: { ...initialLoadingState } };
    default:
      return state;
  }
};

export default usersReducer;
