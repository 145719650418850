// Imports: local files.
import {
  GET_ANSWERS_START,
  GET_ANSWERS_SUCCESS,
  GET_ANSWERS_FAILED,
  GET_ANSWERS_RESET,
  GET_ANSWERS_BY_UNIQUE_ID_START,
  GET_ANSWERS_BY_UNIQUE_ID_SUCCESS,
  GET_ANSWERS_BY_UNIQUE_ID_FAILED,
  GET_ANSWERS_BY_UNIQUE_ID_RESET,
  GET_ANSWER_START,
  GET_ANSWER_SUCCESS,
  GET_ANSWER_FAILED,
  GET_ANSWER_RESET,
  CREATE_ANSWER_START,
  CREATE_ANSWER_SUCCESS,
  CREATE_ANSWER_FAILED,
  CREATE_ANSWER_RESET,
  CREATE_MEMBER_MANUALLY_START,
  CREATE_MEMBER_MANUALLY_SUCCESS,
  CREATE_MEMBER_MANUALLY_FAILED,
  CREATE_MEMBER_MANUALLY_RESET,
  UPDATE_ANSWER_START,
  UPDATE_ANSWER_SUCCESS,
  UPDATE_ANSWER_FAILED,
  UPDATE_ANSWER_RESET,
  DELETE_ANSWER_START,
  DELETE_ANSWER_SUCCESS,
  DELETE_ANSWER_FAILED,
  DELETE_ANSWER_RESET,
} from '../actions';

const initialLoadingState = {
  loading: false,
  success: false,
  data: null,
  error: false,
  errorMessage: null,
};
const initialState = {
  getAll: initialLoadingState,
  getAnswersByUniqueId: initialLoadingState,
  getOne: initialLoadingState,
  create: initialLoadingState,
  createMember: initialLoadingState,
  update: initialLoadingState,
  deleteOne: initialLoadingState,
};

const answerReducer = (state = initialState, action) => {
  switch (action.type) {
    /**
     * =======================================================================
     * Get All Answers
     * =======================================================================
     */
    case GET_ANSWERS_START:
      return { ...state, getAll: { ...action.payload } };
    case GET_ANSWERS_SUCCESS:
      return { ...state, getAll: { ...action.payload } };
    case GET_ANSWERS_FAILED:
      return { ...state, getAll: { ...action.payload } };
    case GET_ANSWERS_RESET:
      return { ...state, getAll: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Get All Answers By Unique Id
     * =======================================================================
     */
    case GET_ANSWERS_BY_UNIQUE_ID_START:
      return { ...state, getAnswersByUniqueId: { ...action.payload } };
    case GET_ANSWERS_BY_UNIQUE_ID_SUCCESS:
      return { ...state, getAnswersByUniqueId: { ...action.payload } };
    case GET_ANSWERS_BY_UNIQUE_ID_FAILED:
      return { ...state, getAnswersByUniqueId: { ...action.payload } };
    case GET_ANSWERS_BY_UNIQUE_ID_RESET:
      return { ...state, getAnswersByUniqueId: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Get One Answer
     * =======================================================================
     */
    case GET_ANSWER_START:
      return { ...state, getOne: { ...action.payload } };
    case GET_ANSWER_SUCCESS:
      return { ...state, getOne: { ...action.payload } };
    case GET_ANSWER_FAILED:
      return { ...state, getOne: { ...action.payload } };
    case GET_ANSWER_RESET:
      return { ...state, getOne: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Create One Answer
     * =======================================================================
     */
    case CREATE_ANSWER_START:
      return { ...state, create: { ...action.payload } };
    case CREATE_ANSWER_SUCCESS:
      return { ...state, create: { ...action.payload } };
    case CREATE_ANSWER_FAILED:
      return { ...state, create: { ...action.payload } };
    case CREATE_ANSWER_RESET:
      return { ...state, create: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Create Member Manually
     * =======================================================================
     */
    case CREATE_MEMBER_MANUALLY_START:
      return { ...state, createMember: { ...action.payload } };
    case CREATE_MEMBER_MANUALLY_SUCCESS:
      return { ...state, createMember: { ...action.payload } };
    case CREATE_MEMBER_MANUALLY_FAILED:
      return { ...state, createMember: { ...action.payload } };
    case CREATE_MEMBER_MANUALLY_RESET:
      return { ...state, createMember: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Create One Answer
     * =======================================================================
     */
    case UPDATE_ANSWER_START:
      return { ...state, update: { ...action.payload } };
    case UPDATE_ANSWER_SUCCESS:
      return { ...state, update: { ...action.payload } };
    case UPDATE_ANSWER_FAILED:
      return { ...state, update: { ...action.payload } };
    case UPDATE_ANSWER_RESET:
      return { ...state, update: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Delete One Answer
     * =======================================================================
     */
    case DELETE_ANSWER_START:
      return { ...state, deleteOne: { ...action.payload } };
    case DELETE_ANSWER_SUCCESS:
      return { ...state, deleteOne: { ...action.payload } };
    case DELETE_ANSWER_FAILED:
      return { ...state, deleteOne: { ...action.payload } };
    case DELETE_ANSWER_RESET:
      return { ...state, deleteOne: { ...initialLoadingState } };
    default:
      return state;
  }
};

export default answerReducer;
