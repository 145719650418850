// Imports: local files.
import {
  GET_ALL_MEMBERS_START,
  GET_ALL_MEMBERS_SUCCESS,
  GET_ALL_MEMBERS_FAILED,
  GET_ALL_MEMBERS_RESET,
  GET_ONE_MEMBER_START,
  GET_ONE_MEMBER_SUCCESS,
  GET_ONE_MEMBER_FAILED,
  GET_ONE_MEMBER_RESET,
  CREATE_MEMBER_START,
  CREATE_MEMBER_SUCCESS,
  CREATE_MEMBER_FAILED,
  CREATE_MEMBER_RESET,
  DELETE_MEMBER_START,
  DELETE_MEMBER_SUCCESS,
  DELETE_MEMBER_FAILED,
  DELETE_MEMBER_RESET,
} from '../actions';

const initialLoadingState = {
  loading: false,
  success: false,
  data: null,
  error: false,
  errorMessage: null,
};
const initialState = {
  getAll: initialLoadingState,
  getOne: initialLoadingState,
  create: initialLoadingState,
  deleteOne: initialLoadingState,
};

const memberReducer = (state = initialState, action) => {
  switch (action.type) {
    /**
     * =======================================================================
     * Get All Members
     * =======================================================================
     */
    case GET_ALL_MEMBERS_START:
      return { ...state, getAll: { ...action.payload } };
    case GET_ALL_MEMBERS_SUCCESS:
      return { ...state, getAll: { ...action.payload } };
    case GET_ALL_MEMBERS_FAILED:
      return { ...state, getAll: { ...action.payload } };
    case GET_ALL_MEMBERS_RESET:
      return { ...state, getAll: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Get One Member
     * =======================================================================
     */
    case GET_ONE_MEMBER_START:
      return { ...state, getOne: { ...action.payload } };
    case GET_ONE_MEMBER_SUCCESS:
      return { ...state, getOne: { ...action.payload } };
    case GET_ONE_MEMBER_FAILED:
      return { ...state, getOne: { ...action.payload } };
    case GET_ONE_MEMBER_RESET:
      return { ...state, getOne: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Create Member
     * =======================================================================
     */
    case CREATE_MEMBER_START:
      return { ...state, create: { ...action.payload } };
    case CREATE_MEMBER_SUCCESS:
      return { ...state, create: { ...action.payload } };
    case CREATE_MEMBER_FAILED:
      return { ...state, create: { ...action.payload } };
    case CREATE_MEMBER_RESET:
      return { ...state, create: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Delete Member
     * =======================================================================
     */
    case DELETE_MEMBER_START:
      return { ...state, deleteOne: { ...action.payload } };
    case DELETE_MEMBER_SUCCESS:
      return { ...state, deleteOne: { ...action.payload } };
    case DELETE_MEMBER_FAILED:
      return { ...state, deleteOne: { ...action.payload } };
    case DELETE_MEMBER_RESET:
      return { ...state, deleteOne: { ...initialLoadingState } };
    default:
      return state;
  }
};

export default memberReducer;
