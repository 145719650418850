// Imports: local files.
import ApiClient from '../../services/ApiClient';
import { showSnackbar, dispatchLoading, dispatchSuccess, dispatchError, getAxiosError } from '../../utils/redux';

// Actions Types: Get All Users.
export const GET_USERS_START = 'GET_USERS_START';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAILED = 'GET_USERS_FAILED';
export const GET_USERS_RESET = 'GET_USERS_RESET';

// Actions Types: Get One User.
export const GET_USER_START = 'GET_USER_START';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILED = 'GET_USER_FAILED';
export const GET_USER_RESET = 'GET_USER_RESET';

// Actions Types: Create User.
export const CREATE_USER_START = 'CREATE_USER_START';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAILED = 'CREATE_USER_FAILED';
export const CREATE_USER_RESET = 'CREATE_USER_RESET';

// Actions Types: Update User.
export const UPDATE_USER_START = 'UPDATE_USER_START';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILED = 'UPDATE_USER_FAILED';
export const UPDATE_USER_RESET = 'UPDATE_USER_RESET';

// Actions Types: Delete One User.
export const DELETE_USER_START = 'DELETE_USER_START';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILED = 'DELETE_USER_FAILED';
export const DELETE_USER_RESET = 'DELETE_USER_RESET';

// Action Creators: Get All Users.
const getUsersStart = (payload) => ({ type: GET_USERS_START, payload });
const getUsersSuccess = (payload) => ({ type: GET_USERS_SUCCESS, payload });
const getUsersFailed = (payload) => ({ type: GET_USERS_FAILED, payload });
const getUsersReset = () => ({ type: GET_USERS_RESET });

// Action Creators: Get One User.
const getUserStart = (payload) => ({ type: GET_USER_START, payload });
const getUserSuccess = (payload) => ({ type: GET_USER_SUCCESS, payload });
const getUserFailed = (payload) => ({ type: GET_USER_FAILED, payload });
const getUserReset = () => ({ type: GET_USER_RESET });

// Action Creators: Create User.
const createUserStart = (payload) => ({ type: CREATE_USER_START, payload });
const createUserSuccess = (payload) => ({ type: CREATE_USER_SUCCESS, payload });
const createUserFailed = (payload) => ({ type: CREATE_USER_FAILED, payload });
const createUserReset = () => ({ type: CREATE_USER_RESET });

// Action Creators: Update User.
const updateUserStart = (payload) => ({ type: UPDATE_USER_START, payload });
const updateUserSuccess = (payload) => ({ type: UPDATE_USER_SUCCESS, payload });
const updateUserFailed = (payload) => ({ type: UPDATE_USER_FAILED, payload });
const updateUserReset = () => ({ type: UPDATE_USER_RESET });

// Action Creators: Delete One User.
const deleteUserStart = (payload) => ({ type: DELETE_USER_START, payload });
const deleteUserSuccess = (payload) => ({ type: DELETE_USER_SUCCESS, payload });
const deleteUserFailed = (payload) => ({ type: DELETE_USER_FAILED, payload });
const deleteUserReset = () => ({ type: DELETE_USER_RESET });

// Actions: Get All Users.
export const getAllUsers = (payload, options = {}) => {
  const { page, limit, pagination, role } = payload;
  const {} = options;
  return async (dispatch) => {
    try {
      dispatch(getUsersStart({ loading: true, success: false, data: null, error: false, errorMessage: null }));

      const result = await ApiClient.get('/users', { params: { page, limit, pagination, role } });
      if (result.data?.success) {
        const { users } = result.data.data;
        dispatch(getUsersSuccess({ loading: false, success: true, data: { users }, error: false, errorMessage: null }));
      } else {
        const errorMessage = result.data?.error || 'Internal Server Error!';
        dispatch(
          getUsersFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: errorMessage,
          })
        );

        dispatch({
          type: '@snackbar/SNACKBAR_OPEN',
          open: true,
          message: errorMessage,
          variant: 'alert',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          alertSeverity: 'error',
          close: true,
        });
      }
    } catch (error) {
      const errorMessage =
        error.response && error.response.data && error.response.data.error
          ? error.response.data.error
          : 'Something went wrong!';

      dispatch(
        getUsersFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: errorMessage,
        })
      );

      dispatch({
        type: '@snackbar/SNACKBAR_OPEN',
        open: true,
        message: errorMessage,
        variant: 'alert',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        alertSeverity: 'error',
        close: true,
      });
    }
  };
};

// Actions: Clear All Users.
export const clearAllUsers = () => getUsersReset();

// Actions: Get One User.
export const getOneUser = (payload, options = {}) => {
  const { userId } = payload;
  const {} = options;
  return async (dispatch) => {
    try {
      dispatch(getUserStart({ loading: true, success: false, data: null, error: false, errorMessage: null }));

      const result = await ApiClient.get(`/users/${userId}`);
      if (result.data?.success) {
        const { user } = result.data.data;
        dispatch(getUserSuccess({ loading: false, success: true, data: { user }, error: false, errorMessage: null }));
      } else {
        const errorMessage = result.data?.error || 'Internal Server Error!';
        dispatch(
          getUserFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: errorMessage,
          })
        );

        dispatch({
          type: '@snackbar/SNACKBAR_OPEN',
          open: true,
          message: errorMessage,
          variant: 'alert',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          alertSeverity: 'error',
          close: true,
        });
      }
    } catch (error) {
      const errorMessage =
        error.response && error.response.data && error.response.data.error
          ? error.response.data.error
          : 'Something went wrong!';

      dispatch(
        getUserFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: errorMessage,
        })
      );

      dispatch({
        type: '@snackbar/SNACKBAR_OPEN',
        open: true,
        message: errorMessage,
        variant: 'alert',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        alertSeverity: 'error',
        close: true,
      });
    }
  };
};

// Actions: Clear One User.
export const clearOneUser = () => getUserReset();

// Actions: Create New User.
export const createUser = (payload, options = {}) => {
  const { name, description, email, password, passwordConfirm, role } = payload;
  const {} = options;
  return async (dispatch) => {
    try {
      dispatch(createUserStart({ loading: true, success: false, data: null, error: false, errorMessage: null }));

      const result = await ApiClient.post(`/users`, { name, description, email, password, passwordConfirm, role });
      if (result.data?.success) {
        const { user } = result.data.data;
        dispatch(
          createUserSuccess({ loading: false, success: true, data: { user }, error: false, errorMessage: null })
        );

        dispatch({
          type: '@snackbar/SNACKBAR_OPEN',
          open: true,
          message: 'User created successfully!',
          variant: 'alert',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          alertSeverity: 'success',
          close: true,
        });
      } else {
        const errorMessage = result.data?.error || 'Internal Server Error!';
        dispatch(
          createUserFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: errorMessage,
          })
        );

        dispatch({
          type: '@snackbar/SNACKBAR_OPEN',
          open: true,
          message: errorMessage,
          variant: 'alert',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          alertSeverity: 'error',
          close: true,
        });
      }
    } catch (error) {
      const errorMessage =
        error.response && error.response.data && error.response.data.error
          ? error.response.data.error
          : 'Something went wrong!';

      dispatch(
        createUserFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: errorMessage,
        })
      );

      dispatch({
        type: '@snackbar/SNACKBAR_OPEN',
        open: true,
        message: errorMessage,
        variant: 'alert',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        alertSeverity: 'error',
        close: true,
      });
    }
  };
};

// Actions: Clear Created User.
export const clearCreatedUser = () => createUserReset();

// Actions: Update User.
export const updateUser = (payload, options = {}) => {
  const { userId, name, description, email, password, passwordConfirm } = payload;
  const {} = options;
  return async (dispatch) => {
    try {
      dispatchLoading(dispatch, updateUserStart);

      const result = await ApiClient.put(`/users/${userId}`, { name, description, email, password, passwordConfirm });
      if (result.data?.success) {
        const { user } = result.data?.data;
        dispatchSuccess(dispatch, updateUserSuccess, user, 'user');
        showSnackbar(dispatch, 'User updated successfully!', 'success');
      } else {
        const errorMessage = result.data?.error || 'Something went wrong!';
        dispatchError(dispatch, updateUserFailed, errorMessage);
        showSnackbar(dispatch, errorMessage, 'error');
      }
    } catch (error) {
      const errorMessage = getAxiosError(error);
      dispatchError(dispatch, updateUserFailed, errorMessage);
      showSnackbar(dispatch, errorMessage, 'error');
    }
  };
};

// Actions: Clear Updated User.
export const clearUpdatedUser = () => updateUserReset();

// Actions: Delete One User.
export const deleteOneUser = (payload, options = {}) => {
  const { userId } = payload;
  const {} = options;
  return async (dispatch) => {
    try {
      dispatch(deleteUserStart({ loading: true, success: false, data: null, error: false, errorMessage: null }));

      const result = await ApiClient.delete(`/users/${userId}`);
      if (result.data?.success) {
        const { user } = result.data.data;
        dispatch(
          deleteUserSuccess({ loading: false, success: true, data: { user }, error: false, errorMessage: null })
        );

        dispatch({
          type: '@snackbar/SNACKBAR_OPEN',
          open: true,
          message: 'User deleted successfully!',
          variant: 'alert',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          alertSeverity: 'success',
          close: true,
        });
      } else {
        const errorMessage = result.data?.error || 'Internal Server Error!';
        dispatch(
          deleteUserFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: errorMessage,
          })
        );

        dispatch({
          type: '@snackbar/SNACKBAR_OPEN',
          open: true,
          message: errorMessage,
          variant: 'alert',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          alertSeverity: 'error',
          close: true,
        });
      }
    } catch (error) {
      const errorMessage =
        error.response && error.response.data && error.response.data.error
          ? error.response.data.error
          : 'Something went wrong!';

      dispatch(
        deleteUserFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: errorMessage,
        })
      );

      dispatch({
        type: '@snackbar/SNACKBAR_OPEN',
        open: true,
        message: errorMessage,
        variant: 'alert',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        alertSeverity: 'error',
        close: true,
      });
    }
  };
};

// Actions: Clear Deleted User.
export const clearDeletedUser = () => deleteUserReset();
