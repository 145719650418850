// third-party
import { FormattedMessage } from 'react-intl';
import jwtDecode from 'jwt-decode';

// assets
import { IconNotes, IconReportAnalytics, IconChartBar } from '@tabler/icons';
import SupervisorAccountOoutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import HighlightOutlinedIcon from '@mui/icons-material/HighlightOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';

const renderMenuItems = () => {
  const token = localStorage.getItem('unicef-token');
  if (token) {
    const { role } = jwtDecode(token);
    let children = [];
    if (role === 1) {
      children = [
        {
          id: 'dashboard',
          title: <FormattedMessage id="dashboard" />,
          type: 'item',
          url: '/',
          icon: IconReportAnalytics,
          breadcrumbs: false,
        },
        {
          id: 'statistics',
          title: <FormattedMessage id="statistics" />,
          type: 'item',
          url: '/statistics',
          icon: IconChartBar,
          breadcrumbs: false,
        },
        {
          id: 'admins',
          title: <FormattedMessage id="admins" />,
          type: 'item',
          url: '/users/admins',
          icon: SupervisorAccountOoutlinedIcon,
          breadcrumbs: false,
        },
        {
          id: 'organisations',
          title: <FormattedMessage id="organisations" />,
          type: 'item',
          url: '/users/organisations',
          icon: BusinessOutlinedIcon,
          breadcrumbs: false,
        },
        {
          id: 'workshopTypes',
          title: <FormattedMessage id="workshopTypes" />,
          type: 'item',
          url: '/workshopTypes',
          icon: HighlightOutlinedIcon,
          breadcrumbs: false,
        },
        {
          id: 'workshops',
          title: <FormattedMessage id="workshops" />,
          type: 'item',
          url: '/workshops',
          icon: IconNotes,
          breadcrumbs: false,
        },
      ];
    } else if (role === 2) {
      children = [
        {
          id: 'profile',
          title: <FormattedMessage id="profile" />,
          type: 'item',
          url: '/',
          icon: PermIdentityOutlinedIcon,
          breadcrumbs: false,
        },
        {
          id: 'statistics',
          title: <FormattedMessage id="statistics" />,
          type: 'item',
          url: '/statistics',
          icon: IconChartBar,
          breadcrumbs: false,
        },
        {
          id: 'workshopTypes',
          title: <FormattedMessage id="workshopTypes" />,
          type: 'item',
          url: '/workshopTypes',
          icon: HighlightOutlinedIcon,
          breadcrumbs: false,
        },
        {
          id: 'workshops',
          title: <FormattedMessage id="workshops" />,
          type: 'item',
          url: '/workshops',
          icon: IconNotes,
          breadcrumbs: false,
        },
      ];
    }
    return children;
  }
};

const other = {
  id: 'menu-items',
  type: 'group',
  children: renderMenuItems(),
};

export default other;
