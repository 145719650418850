// Imports: local files.
import ApiClient from '../../services/ApiClient';
import { showSnackbar, dispatchLoading, dispatchSuccess, dispatchError, getAxiosError } from '../../utils/redux';

// Name of property to be used in reducer data.
const applicationsName = 'applications';
const applicationName = 'application';

// Actions Types: Get All Answers
export const GET_APPLICATIONS_START = 'GET_APPLICATIONS_START';
export const GET_APPLICATIONS_SUCCESS = 'GET_APPLICATIONS_SUCCESS';
export const GET_APPLICATIONS_FAILED = 'GET_APPLICATIONS_FAILED';
export const GET_APPLICATIONS_RESET = 'GET_APPLICATIONS_RESET';

// Actions Types: Get One APPLICATION.
export const GET_APPLICATION_START = 'GET_APPLICATION_START';
export const GET_APPLICATION_SUCCESS = 'GET_APPLICATION_SUCCESS';
export const GET_APPLICATION_FAILED = 'GET_APPLICATION_FAILED';
export const GET_APPLICATION_RESET = 'GET_APPLICATION_RESET';

// Actions Types: Create Application.
export const CREATE_APPLICATION_START = 'CREATE_APPLICATION_START';
export const CREATE_APPLICATION_SUCCESS = 'CREATE_APPLICATION_SUCCESS';
export const CREATE_APPLICATION_FAILED = 'CREATE_APPLICATION_FAILED';
export const CREATE_APPLICATION_RESET = 'CREATE_APPLICATION_RESET';

// Actions Types: Update APPLICATION.
export const UPDATE_APPLICATION_START = 'UPDATE_APPLICATION_START';
export const UPDATE_APPLICATION_SUCCESS = 'UPDATE_APPLICATION_SUCCESS';
export const UPDATE_APPLICATION_FAILED = 'UPDATE_APPLICATION_FAILED';
export const UPDATE_APPLICATION_RESET = 'UPDATE_APPLICATION_RESET';

// Actions Types: Delete APPLICATION
export const DELETE_APPLICATION_START = 'DELETE_APPLICATION_START';
export const DELETE_APPLICATION_SUCCESS = 'DELETE_APPLICATION_SUCCESS';
export const DELETE_APPLICATION_FAILED = 'DELETE_APPLICATION_FAILED';
export const DELETE_APPLICATION_RESET = 'DELETE_APPLICATION_RESET';

// Action Creators: Get Answers.
const getApplicationsStart = (payload) => ({ type: GET_APPLICATIONS_START, payload });
const getApplicationsSuccess = (payload) => ({ type: GET_APPLICATIONS_SUCCESS, payload });
const getApplicationsFailed = (payload) => ({ type: GET_APPLICATIONS_FAILED, payload });
const getApplicationsReset = () => ({ type: GET_APPLICATIONS_RESET });

// Action Creators: Get One APPLICATION.
const getApplicationStart = (payload) => ({ type: GET_APPLICATION_START, payload });
const getApplicationSuccess = (payload) => ({ type: GET_APPLICATION_SUCCESS, payload });
const getApplicationFailed = (payload) => ({ type: GET_APPLICATION_FAILED, payload });
const getApplicationReset = () => ({ type: GET_APPLICATION_RESET });

// Action Creators: Create Application.
const createApplicationStart = (payload) => ({ type: CREATE_APPLICATION_START, payload });
const createApplicationSuccess = (payload) => ({ type: CREATE_APPLICATION_SUCCESS, payload });
const createApplicationFailed = (payload) => ({ type: CREATE_APPLICATION_FAILED, payload });
const createApplicationReset = () => ({ type: CREATE_APPLICATION_RESET });

// Action Creators: Update APPLICATION.
const updateApplicationStart = (payload) => ({ type: UPDATE_APPLICATION_START, payload });
const updateApplicationSuccess = (payload) => ({ type: UPDATE_APPLICATION_SUCCESS, payload });
const updateApplicationFailed = (payload) => ({ type: UPDATE_APPLICATION_FAILED, payload });
const updateApplicationReset = () => ({ type: UPDATE_APPLICATION_RESET });

// Action Creators: Delete Application.
const deleteApplicationStart = (payload) => ({ type: DELETE_APPLICATION_START, payload });
const deleteApplicationSuccess = (payload) => ({ type: DELETE_APPLICATION_SUCCESS, payload });
const deleteApplicationFailed = (payload) => ({ type: DELETE_APPLICATION_FAILED, payload });
const deleteApplicationReset = () => ({ type: DELETE_APPLICATION_RESET });

// Actions: Get All Applications.
export const getAllApplications = (payload, options = {}) => {
  const { page, limit, pagination, workshop, survey, acceptedApplication } = payload;
  const {} = options;
  return async (dispatch) => {
    try {
      dispatchLoading(dispatch, getApplicationsStart);

      const result = await ApiClient.get('/applications', {
        params: { page, limit, pagination, workshop, survey, acceptedApplication },
      });
      if (result.data?.success) {
        const { applications } = result.data?.data;
        dispatchSuccess(dispatch, getApplicationsSuccess, applications, applicationsName);
      } else {
        const errorMessage = result.data?.error || 'Something went wrong!';
        dispatchError(dispatch, getApplicationsFailed, errorMessage);
        showSnackbar(dispatch, errorMessage, 'error');
      }
    } catch (error) {
      const errorMessage = getAxiosError(error);
      dispatchError(dispatch, getApplicationsFailed, errorMessage);
      showSnackbar(dispatch, errorMessage, 'error');
    }
  };
};

// Actions: Clear All Applications.
export const clearAllApplications = () => getApplicationsReset();

// Actions: Get One Application.
export const getOneApplication = (payload, options = {}) => {
  const { applicationId } = payload;
  const {} = options;
  return async (dispatch) => {
    try {
      dispatchLoading(dispatch, getApplicationStart);

      const result = await ApiClient.get(`/applications/${applicationId}`);
      if (result.data?.success) {
        const { application } = result.data?.data;
        dispatchSuccess(dispatch, getApplicationSuccess, application, applicationName);
      } else {
        const errorMessage = result.data?.error || 'Something went wrong!';
        dispatchError(dispatch, getApplicationFailed, errorMessage);
        showSnackbar(dispatch, errorMessage, 'error');
      }
    } catch (error) {
      const errorMessage = getAxiosError(error);
      dispatchError(dispatch, getApplicationFailed, errorMessage);
      showSnackbar(dispatch, errorMessage, 'error');
    }
  };
};

// Actions: Clear One Application.
export const clearOneApplication = () => getApplicationReset();

// Actions: Create New Application.
export const createApplication = (payload, options = {}) => {
  const { uuid, workshop, survey, answers } = payload;
  const { navigate, pathName, shouldRedirect } = options;
  return async (dispatch) => {
    try {
      dispatchLoading(dispatch, createApplicationStart);
      const result = await ApiClient.post(`/applications`, {
        uuid,
        workshop,
        answers,
        survey,
      });
      if (result.data?.success) {
        const { application } = result.data?.data;
        dispatchSuccess(dispatch, createApplicationSuccess, application, 'applications');
        showSnackbar(dispatch, 'Application submited successfully!', 'success');
        shouldRedirect && navigate(pathName, { state: { redirectPage: true, isApplication: true } });
      } else {
        const errorMessage = result.data?.error || 'Something went wrong!';
        dispatchError(dispatch, createApplicationFailed, errorMessage);
        showSnackbar(dispatch, errorMessage, 'error');
      }
    } catch (error) {
      const errorMessage = getAxiosError(error);
      dispatchError(dispatch, createApplicationFailed, errorMessage);
      showSnackbar(dispatch, errorMessage, 'error');
    }
  };
};

// Actions: Clear Created Application.
export const clearCreatedApplication = () => createApplicationReset();

// Actions: Update Application.
export const updateApplication = (payload, options = {}) => {
  const { applicationId, acceptanceCriteriaResults } = payload;
  const {} = options;
  return async (dispatch) => {
    try {
      dispatchLoading(dispatch, updateApplicationStart);

      const result = await ApiClient.put(`/applications/${applicationId}`, {
        acceptanceCriteriaResults,
      });
      if (result.data?.success) {
        const { application } = result.data?.data;
        dispatchSuccess(dispatch, updateApplicationSuccess, application, applicationName);
        showSnackbar(dispatch, 'Application updated successfully!', 'success');
      } else {
        const errorMessage = result.data?.error || 'Something went wrong!';
        dispatchError(dispatch, updateApplicationFailed, errorMessage);
        showSnackbar(dispatch, errorMessage, 'error');
      }
    } catch (error) {
      const errorMessage = getAxiosError(error);
      dispatchError(dispatch, updateApplicationFailed, errorMessage);
      showSnackbar(dispatch, errorMessage, 'error');
    }
  };
};

// Actions: Clear Updated Application.
export const clearUpdatedApplication = () => updateApplicationReset();

// Actions: Delete One User.
export const deleteOneApplication = (payload, options = {}) => {
  const { applicationId } = payload;
  const {} = options;
  return async (dispatch) => {
    try {
      dispatchLoading(dispatch, deleteApplicationStart);

      const result = await ApiClient.delete(`/applications/${applicationId}`);
      if (result.data?.success) {
        const { application } = result.data?.data;
        dispatchSuccess(dispatch, deleteApplicationSuccess, application, applicationName);
        showSnackbar(dispatch, 'Application deleted successfully!', 'success');
      } else {
        const errorMessage = result.data?.error || 'Something went wrong!';
        dispatchError(dispatch, deleteApplicationFailed, errorMessage);
        showSnackbar(dispatch, errorMessage, 'error');
      }
    } catch (error) {
      const errorMessage = getAxiosError(error);
      dispatchError(dispatch, deleteApplicationFailed, errorMessage);
      showSnackbar(dispatch, errorMessage, 'error');
    }
  };
};

// Actions: Clear Deleted Application.
export const clearDeletedApplication = () => deleteApplicationReset();
