import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// reducer import
import customizationReducer from './customization';
import snackbarReducer from './snackbar';
import cartReducer from './cart';
import authReducer from './auth';
import usersReducer from './users';
import workshopTypesReducer from './workshoptypes';
import workshopsReducer from './workshops';
import reportsReducer from './reports';
import answersReducer from './answers';
import applicationsReducer from './applications';
import membersReducer from './members';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  customization: customizationReducer,
  snackbar: snackbarReducer,
  cart: persistReducer(
    {
      key: 'cart',
      storage,
      keyPrefix: 'berry-',
    },
    cartReducer
  ),
  auth: authReducer,
  users: usersReducer,
  workshopTypes: workshopTypesReducer,
  workshops: workshopsReducer,
  reports: reportsReducer,
  answers: answersReducer,
  applications: applicationsReducer,
  members: membersReducer,
});

export default reducer;
