// Imports: local files.
import ApiClient from '../../services/ApiClient';
import { showSnackbar, dispatchLoading, dispatchSuccess, dispatchError, getAxiosError } from '../../utils/redux';

// Name of property to be used in reducer data.
const workshopsName = 'workshops';
const workshopName = 'workshop';
const surveysName = 'surveys';
const questionsName = 'questions';

// Actions Types: Get All Workshop Types.
export const GET_WORKSHOPS_START = 'GET_WORKSHOPS_START';
export const GET_WORKSHOPS_SUCCESS = 'GET_WORKSHOPS_SUCCESS';
export const GET_WORKSHOPS_FAILED = 'GET_WORKSHOPS_FAILED';
export const GET_WORKSHOPS_RESET = 'GET_WORKSHOPS_RESET';

// Actions Types: Get All Workshop Surveys.
export const GET_WORKSHOP_SURVEYS_START = 'GET_WORKSHOP_SURVEYS_START';
export const GET_WORKSHOP_SURVEYS_SUCCESS = 'GET_WORKSHOP_SURVEYS_SUCCESS';
export const GET_WORKSHOP_SURVEYS_FAILED = 'GET_WORKSHOP_SURVEYS_FAILED';
export const GET_WORKSHOP_SURVEYS_RESET = 'GET_WORKSHOP_SURVEYS_RESET';

// Actions Types: Get All Survey Questions.
export const GET_SURVEY_QUESTIONS_START = 'GET_SURVEY_QUESTIONS_START';
export const GET_SURVEY_QUESTIONS_SUCCESS = 'GET_SURVEY_QUESTIONS_SUCCESS';
export const GET_SURVEY_QUESTIONS_FAILED = 'GET_SURVEY_QUESTIONS_FAILED';
export const GET_SURVEY_QUESTIONS_RESET = 'GET_SURVEY_QUESTIONS_RESET';

// Actions Types: Get All Workshop Type Questions.
export const GET_WORKSHOP_TYPE_QUESTIONS_START = 'GET_WORKSHOP_TYPE_QUESTIONS_START';
export const GET_WORKSHOP_TYPE_QUESTIONS_SUCCESS = 'GET_WORKSHOP_TYPE_QUESTIONS_SUCCESS';
export const GET_WORKSHOP_TYPE_QUESTIONS_FAILED = 'GET_WORKSHOP_TYPE_QUESTIONS_FAILED';
export const GET_WORKSHOP_TYPE_QUESTIONS_RESET = 'GET_WORKSHOP_TYPE_QUESTIONS_RESET';

// Actions Types: Get All Questions For a Member.
export const GET_MEMBER_QUESTIONS_START = 'GET_MEMBER_QUESTIONS_START';
export const GET_MEMBER_QUESTIONS_SUCCESS = 'GET_MEMBER_QUESTIONS_SUCCESS';
export const GET_MEMBER_QUESTIONS_FAILED = 'GET_MEMBER_QUESTIONS_FAILED';
export const GET_MEMBER_QUESTIONS_RESET = 'GET_MEMBER_QUESTIONS_RESET';

// Actions Types: Get One User.
export const GET_WORKSHOP_START = 'GET_WORKSHOP_START';
export const GET_WORKSHOP_SUCCESS = 'GET_WORKSHOP_SUCCESS';
export const GET_WORKSHOP_FAILED = 'GET_WORKSHOP_FAILED';
export const GET_WORKSHOP_RESET = 'GET_WORKSHOP_RESET';

// Actions Types: Create Workshop Type.
export const CREATE_WORKSHOP_START = 'CREATE_WORKSHOP_START';
export const CREATE_WORKSHOP_SUCCESS = 'CREATE_WORKSHOP_SUCCESS';
export const CREATE_WORKSHOP_FAILED = 'CREATE_WORKSHOP_FAILED';
export const CREATE_WORKSHOP_RESET = 'CREATE_WORKSHOP_RESET';

// Actions Types: Update Workshop Type.
export const UPDATE_WORKSHOP_START = 'UPDATE_WORKSHOP_START';
export const UPDATE_WORKSHOP_SUCCESS = 'UPDATE_WORKSHOP_SUCCESS';
export const UPDATE_WORKSHOP_FAILED = 'UPDATE_WORKSHOP_FAILED';
export const UPDATE_WORKSHOP_RESET = 'UPDATE_WORKSHOP_RESET';

// Actions Types: Delete Workshop Type.
export const DELETE_WORKSHOP_START = 'DELETE_WORKSHOP_START';
export const DELETE_WORKSHOP_SUCCESS = 'DELETE_WORKSHOP_SUCCESS';
export const DELETE_WORKSHOP_FAILED = 'DELETE_WORKSHOP_FAILED';
export const DELETE_WORKSHOP_RESET = 'DELETE_WORKSHOP_RESET';

// Action Creators: Get All Workshops.
const getWorkshopsStart = (payload) => ({ type: GET_WORKSHOPS_START, payload });
const getWorkshopsSuccess = (payload) => ({ type: GET_WORKSHOPS_SUCCESS, payload });
const getWorkshopsFailed = (payload) => ({ type: GET_WORKSHOPS_FAILED, payload });
const getWorkshopsReset = () => ({ type: GET_WORKSHOPS_RESET });

// Action Creators: Get All Workshop Surveys.
const getWorkshopSurveysStart = (payload) => ({ type: GET_WORKSHOP_SURVEYS_START, payload });
const getWorkshopSurveysSuccess = (payload) => ({ type: GET_WORKSHOP_SURVEYS_SUCCESS, payload });
const getWorkshopSurveysFailed = (payload) => ({ type: GET_WORKSHOP_SURVEYS_FAILED, payload });
const getWorkshopSurveysReset = () => ({ type: GET_WORKSHOP_SURVEYS_RESET });

// Action Creators: Get Survey Questions.
const getSurveyQuestionsStart = (payload) => ({ type: GET_SURVEY_QUESTIONS_START, payload });
const getSurveyQuestionsSuccess = (payload) => ({ type: GET_SURVEY_QUESTIONS_SUCCESS, payload });
const getSurveyQuestionsFailed = (payload) => ({ type: GET_SURVEY_QUESTIONS_FAILED, payload });
const getSurveyQuestionsReset = () => ({ type: GET_SURVEY_QUESTIONS_RESET });

// Action Creators: Get Workshop Type Questions.
const getWorkshopTypeQuestionsStart = (payload) => ({ type: GET_WORKSHOP_TYPE_QUESTIONS_START, payload });
const getWorkshopTypeQuestionsSuccess = (payload) => ({ type: GET_WORKSHOP_TYPE_QUESTIONS_SUCCESS, payload });
const getWorkshopTypeQuestionsFailed = (payload) => ({ type: GET_WORKSHOP_TYPE_QUESTIONS_FAILED, payload });
const getWorkshopTypeQuestionsReset = () => ({ type: GET_WORKSHOP_TYPE_QUESTIONS_RESET });

// Action Creators: Get Member Questions.
const getMemberQuestionsStart = (payload) => ({ type: GET_MEMBER_QUESTIONS_START, payload });
const getMemberQuestionsSuccess = (payload) => ({ type: GET_MEMBER_QUESTIONS_SUCCESS, payload });
const getMemberQuestionsFailed = (payload) => ({ type: GET_MEMBER_QUESTIONS_FAILED, payload });
const getMemberQuestionsReset = () => ({ type: GET_MEMBER_QUESTIONS_RESET });

// Action Creators: Get One Workshop.
const getWorkshopStart = (payload) => ({ type: GET_WORKSHOP_START, payload });
const getWorkshopSuccess = (payload) => ({ type: GET_WORKSHOP_SUCCESS, payload });
const getWorkshopFailed = (payload) => ({ type: GET_WORKSHOP_FAILED, payload });
const getWorkshopReset = () => ({ type: GET_WORKSHOP_RESET });

// Action Creators: Create Workshop.
const createWorkshopStart = (payload) => ({ type: CREATE_WORKSHOP_START, payload });
const createWorkshopSuccess = (payload) => ({ type: CREATE_WORKSHOP_SUCCESS, payload });
const createWorkshopFailed = (payload) => ({ type: CREATE_WORKSHOP_FAILED, payload });
const createWorkshopReset = () => ({ type: CREATE_WORKSHOP_RESET });

// Action Creators: Update Workshop.
const updateWorkshopStart = (payload) => ({ type: UPDATE_WORKSHOP_START, payload });
const updateWorkshopSuccess = (payload) => ({ type: UPDATE_WORKSHOP_SUCCESS, payload });
const updateWorkshopFailed = (payload) => ({ type: UPDATE_WORKSHOP_FAILED, payload });
const updateWorkshopReset = () => ({ type: UPDATE_WORKSHOP_RESET });

// Action Creators: Delete Workshop.
const deleteWorkshopStart = (payload) => ({ type: DELETE_WORKSHOP_START, payload });
const deleteWorkshopSuccess = (payload) => ({ type: DELETE_WORKSHOP_SUCCESS, payload });
const deleteWorkshopFailed = (payload) => ({ type: DELETE_WORKSHOP_FAILED, payload });
const deleteWorkshopReset = () => ({ type: DELETE_WORKSHOP_RESET });

// Actions: Get All Workshops.
export const getAllWorkshops = (payload, options = {}) => {
  const { page, limit, pagination, isOther, availability, deleted, type, date, endDate, organisation } = payload;
  const {} = options;
  return async (dispatch) => {
    try {
      dispatchLoading(dispatch, getWorkshopsStart);

      const result = await ApiClient.get('/workshops', {
        params: { page, limit, pagination, isOther, availability, deleted, type, date, endDate, organisation },
      });
      if (result.data?.success) {
        const { workshops } = result.data?.data;
        dispatchSuccess(dispatch, getWorkshopsSuccess, workshops, workshopsName);
      } else {
        const errorMessage = result.data?.error || 'Something went wrong!';
        dispatchError(dispatch, getWorkshopsFailed, errorMessage);
        showSnackbar(dispatch, errorMessage, 'error');
      }
    } catch (error) {
      const errorMessage = getAxiosError(error);
      dispatchError(dispatch, getWorkshopsFailed, errorMessage);
      showSnackbar(dispatch, errorMessage, 'error');
    }
  };
};

// Actions: Clear All Workshops.
export const clearAllWorkshops = () => getWorkshopsReset();

// Actions: Get All Workshop Surveys.
export const getAllWorkshopSurveys = (payload, options = {}) => {
  const { workshopId } = payload;
  const {} = options;
  return async (dispatch) => {
    try {
      dispatchLoading(dispatch, getWorkshopSurveysStart);

      const result = await ApiClient.get(`/workshops/${workshopId}/surveys`);
      if (result.data?.success) {
        const { data } = result.data;
        dispatchSuccess(dispatch, getWorkshopSurveysSuccess, data, surveysName);
      } else {
        const errorMessage = result.data?.error || 'Something went wrong!';
        dispatchError(dispatch, getWorkshopSurveysFailed, errorMessage);
        showSnackbar(dispatch, errorMessage, 'error');
      }
    } catch (error) {
      const errorMessage = getAxiosError(error);
      dispatchError(dispatch, getWorkshopSurveysFailed, errorMessage);
      showSnackbar(dispatch, errorMessage, 'error');
    }
  };
};

// Actions: Clear Get All Workshop Surveys.
export const clearGetAllWorkshopSurveys = () => getWorkshopSurveysReset();

// Actions: Get All Survey Questions.
export const getAllSurveyQuestions = (payload, options = {}) => {
  const { surveyId, type, page, limit, pagination } = payload;
  const {} = options;
  return async (dispatch) => {
    try {
      dispatchLoading(dispatch, getSurveyQuestionsStart);

      const result = await ApiClient.get(`/workshops/surveys/questions`, {
        params: { page, limit, pagination, surveyId },
      });
      if (result.data?.success) {
        const { questions } = result.data?.data;
        // dispatchSuccess(dispatch, getSurveyQuestionsSuccess, questions, questionsName);
        dispatch(
          getSurveyQuestionsSuccess({
            loading: false,
            success: true,
            data: { questions, type },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        const errorMessage = result.data?.error || 'Something went wrong!';
        dispatchError(dispatch, getSurveyQuestionsFailed, errorMessage);
        showSnackbar(dispatch, errorMessage, 'error');
      }
    } catch (error) {
      const errorMessage = getAxiosError(error);
      dispatchError(dispatch, getSurveyQuestionsFailed, errorMessage);
      showSnackbar(dispatch, errorMessage, 'error');
    }
  };
};

// Actions: Clear Get All Survey Questions.
export const clearGetAllSurveyQuestions = () => getSurveyQuestionsReset();

// Actions: Get All WorkshopType Questions.
export const getAllWorkshopTypeQuestions = (payload, options = {}) => {
  const { workshopTypeId, surveyType, page, limit, pagination } = payload;
  const {} = options;
  return async (dispatch) => {
    try {
      // dispatchLoading(dispatch, getWorkshopTypeQuestionsStart);

      const result = await ApiClient.get(`/workshops/workshopType/questions`, {
        params: { page, limit, pagination, workshopTypeId, surveyType },
      });
      if (result.data?.success) {
        const { questions } = result.data?.data;
        // dispatchSuccess(dispatch, getWorkshopTypeQuestionsSuccess, questions, questionsName);
        dispatch(
          getWorkshopTypeQuestionsSuccess({
            loading: false,
            success: true,
            data: { questions },
            error: false,
            errorMessage: null,
            dataDocs: [],
          })
        );
      } else {
        const errorMessage = result.data?.error || 'Something went wrong!';
        dispatchError(dispatch, getWorkshopTypeQuestionsFailed, errorMessage);
        showSnackbar(dispatch, errorMessage, 'error');
      }
    } catch (error) {
      const errorMessage = getAxiosError(error);
      dispatchError(dispatch, getWorkshopTypeQuestionsFailed, errorMessage);
      showSnackbar(dispatch, errorMessage, 'error');
    }
  };
};

// Actions: Clear Get All WorkshopType Questions.
export const clearGetAllWorkshopTypeQuestions = () => getWorkshopTypeQuestionsReset();

// Actions: Get All Member Questions.
export const getMemberQuestions = (payload, options = {}) => {
  const { page, limit, pagination, workshopId, surveyId } = payload;
  const {} = options;
  return async (dispatch) => {
    try {
      dispatchLoading(dispatch, getMemberQuestionsStart);

      const result = await ApiClient.get(`/workshops/member/questions`, {
        params: { page, limit, pagination, workshopId, surveyId },
      });
      if (result.data?.success) {
        const { questions } = result.data.data;
        dispatchSuccess(dispatch, getMemberQuestionsSuccess, questions, 'questions');
      } else {
        const errorMessage = result.data?.error || 'Something went wrong!';
        dispatchError(dispatch, getMemberQuestionsFailed, errorMessage);
        showSnackbar(dispatch, errorMessage, 'error');
      }
    } catch (error) {
      const errorMessage = getAxiosError(error);
      dispatchError(dispatch, getMemberQuestionsFailed, errorMessage);
      showSnackbar(dispatch, errorMessage, 'error');
    }
  };
};

// Actions: Clear Get All Member Questions.
export const clearGetAllMemberQuestions = () => getMemberQuestionsReset();

// Actions: Get One Workshop.
export const getOneWorkshop = (payload, options = {}) => {
  const { workshopId } = payload;
  const {} = options;
  return async (dispatch) => {
    try {
      dispatchLoading(dispatch, getWorkshopStart);

      const result = await ApiClient.get(`/workshops/${workshopId}`);
      if (result.data?.success) {
        const { workshop } = result.data?.data;
        dispatchSuccess(dispatch, getWorkshopSuccess, workshop, workshopName);
      } else {
        const errorMessage = result.data?.error || 'Something went wrong!';
        dispatchError(dispatch, getWorkshopFailed, errorMessage);
        showSnackbar(dispatch, errorMessage, 'error');
      }
    } catch (error) {
      const errorMessage = getAxiosError(error);
      dispatchError(dispatch, getWorkshopFailed, errorMessage);
      showSnackbar(dispatch, errorMessage, 'error');
    }
  };
};

// Actions: Clear One Workshop.
export const clearOneWorkshop = () => getWorkshopReset();

// Actions: Create New Workshop.
export const createWorkshop = (payload, options = {}) => {
  const {
    name,
    description,
    startDate,
    endDate,
    type,
    isOther,
    otherName,
    otherDescription,
    organisation,
    application,
    preSurvey,
    firstPostSurvey,
    secondPostSurvey,
    thirdPostSurvey,
    fourthPostSurvey,
    acceptanceCriteriaSurvey,
  } = payload;
  const {} = options;
  return async (dispatch) => {
    try {
      dispatchLoading(dispatch, createWorkshopStart);
      const result = await ApiClient.post(`/workshops`, {
        name,
        description,
        startDate,
        endDate,
        type,
        isOther,
        otherName,
        otherDescription,
        organisation,
        application,
        preSurvey,
        firstPostSurvey,
        secondPostSurvey,
        thirdPostSurvey,
        fourthPostSurvey,
        acceptanceCriteriaSurvey,
      });
      if (result.data?.success) {
        const { workshop } = result.data?.data;
        dispatchSuccess(dispatch, createWorkshopSuccess, workshop, workshopName);
        showSnackbar(dispatch, 'Workshop created successfully!', 'success');
      } else {
        const errorMessage = result.data?.error || 'Something went wrong!';
        dispatchError(dispatch, createWorkshopFailed, errorMessage);
        showSnackbar(dispatch, errorMessage, 'error');
      }
    } catch (error) {
      const errorMessage = getAxiosError(error);
      dispatchError(dispatch, createWorkshopFailed, errorMessage);
      showSnackbar(dispatch, errorMessage, 'error');
    }
  };
};

// Actions: Clear Created Workshop.
export const clearCreatedWorkshop = () => createWorkshopReset();

// Actions: Update Workshop.
export const updateWorkshop = (payload, options = {}) => {
  const {
    workshopId,
    name,
    description,
    startDate,
    endDate,
    type,
    isOther,
    otherName,
    otherDescription,
    organisation,
    application,
    preSurvey,
    firstPostSurvey,
    secondPostSurvey,
    thirdPostSurvey,
    fourthPostSurvey,
    acceptanceCriteriaSurvey,
  } = payload;
  const {} = options;
  return async (dispatch) => {
    try {
      dispatchLoading(dispatch, updateWorkshopStart);

      const result = await ApiClient.put(`/workshops/${workshopId}`, {
        name,
        description,
        startDate,
        endDate,
        type,
        isOther,
        otherName,
        otherDescription,
        organisation,
        application,
        preSurvey,
        firstPostSurvey,
        secondPostSurvey,
        thirdPostSurvey,
        fourthPostSurvey,
        acceptanceCriteriaSurvey,
      });
      if (result.data?.success) {
        const { workshop } = result.data?.data;
        dispatchSuccess(dispatch, updateWorkshopSuccess, workshop, workshopName);
        showSnackbar(dispatch, 'Workshop updated successfully!', 'success');
      } else {
        const errorMessage = result.data?.error || 'Something went wrong!';
        dispatchError(dispatch, updateWorkshopFailed, errorMessage);
        showSnackbar(dispatch, errorMessage, 'error');
      }
    } catch (error) {
      const errorMessage = getAxiosError(error);
      dispatchError(dispatch, updateWorkshopFailed, errorMessage);
      showSnackbar(dispatch, errorMessage, 'error');
    }
  };
};

// Actions: Clear Updated Workshop.
export const clearUpdatedWorkshop = () => updateWorkshopReset();

// Actions: Delete One User.
export const deleteOneWorkshop = (payload, options = {}) => {
  const { workshopId } = payload;
  const {} = options;
  return async (dispatch) => {
    try {
      dispatchLoading(dispatch, deleteWorkshopStart);

      const result = await ApiClient.delete(`/workshops/${workshopId}`);
      if (result.data?.success) {
        const { workshop } = result.data?.data;
        dispatchSuccess(dispatch, deleteWorkshopSuccess, workshop, workshopName);
        showSnackbar(dispatch, 'Workshop deleted successfully!', 'success');
      } else {
        const errorMessage = result.data?.error || 'Something went wrong!';
        dispatchError(dispatch, deleteWorkshopFailed, errorMessage);
        showSnackbar(dispatch, errorMessage, 'error');
      }
    } catch (error) {
      const errorMessage = getAxiosError(error);
      dispatchError(dispatch, deleteWorkshopFailed, errorMessage);
      showSnackbar(dispatch, errorMessage, 'error');
    }
  };
};

// Actions: Clear Deleted Workshop.
export const clearDeletedWorkshop = () => deleteWorkshopReset();
