import { lazy } from 'react';

// project imports
import GuestGuard from 'utils/route-guard/GuestGuard';
import MinimalLayout from 'layout/MinimalLayout';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import NavMotion from 'layout/NavMotion';

// login routing
const ApplicationForm = Loadable(lazy(() => import('views/forms/ApplicationForm')));
const PreForm = Loadable(lazy(() => import('views/forms/PreForm')));
const FirstPostForm = Loadable(lazy(() => import('views/forms/FirstPostForm')));
const SecondPostForm = Loadable(lazy(() => import('views/forms/SecondPostForm')));
const ThirdPostForm = Loadable(lazy(() => import('views/forms/ThirdPostForm')));
const FourthPostForm = Loadable(lazy(() => import('views/forms/FourthPostForm')));
const AppliedSuccessfully = Loadable(lazy(() => import('views/pages/applied/AppliedSuccessfully')));

const FormRoutes = {
  path: '/',
  element: (
    // <GuestGuard>
    <MinimalLayout />
    // </GuestGuard>
  ),
  children: [
    {
      path: '/workshops/:id/application',
      element: <ApplicationForm />,
    },
    {
      path: '/workshops/:id/pre',
      element: <PreForm />,
    },
    {
      path: '/workshops/:id/firstPost',
      element: <FirstPostForm />,
    },
    {
      path: '/workshops/:id/secondPost',
      element: <SecondPostForm />,
    },
    {
      path: '/workshops/:id/thirdPost',
      element: <ThirdPostForm />,
    },
    {
      path: '/workshops/:id/fourthPost',
      element: <FourthPostForm />,
    },
    {
      path: '/applied',
      element: <AppliedSuccessfully />,
    },
  ],
};

export default FormRoutes;
