// Imports: local files.
import ApiClient from '../../services/ApiClient';
import { showSnackbar, dispatchLoading, dispatchSuccess, dispatchError, getAxiosError } from '../../utils/redux';

// Name of property to be used in reducer data.
const membersName = 'members';
const memberName = 'member';

// Action Types: Get All Members
export const GET_ALL_MEMBERS_START = 'GET_ALL_MEMBERS_START';
export const GET_ALL_MEMBERS_SUCCESS = 'GET_ALL_MEMBERS_SUCCESS';
export const GET_ALL_MEMBERS_FAILED = 'GET_ALL_MEMBERS_FAILED';
export const GET_ALL_MEMBERS_RESET = 'GET_ALL_MEMBERS_RESET';

// Action Types: Get One Member
export const GET_ONE_MEMBER_START = 'GET_ONE_MEMBER_START';
export const GET_ONE_MEMBER_SUCCESS = 'GET_ONE_MEMBER_SUCCESS';
export const GET_ONE_MEMBER_FAILED = 'GET_ONE_MEMBER_FAILED';
export const GET_ONE_MEMBER_RESET = 'GET_ONE_MEMBER_RESET';

// Action Types: Create Member
export const CREATE_MEMBER_START = 'CREATE_MEMBER_START';
export const CREATE_MEMBER_SUCCESS = 'CREATE_MEMBER_SUCCESS';
export const CREATE_MEMBER_FAILED = 'CREATE_MEMBER_FAILED';
export const CREATE_MEMBER_RESET = 'CREATE_MEMBER_RESET';

// Actions Types: Delete One Member.
export const DELETE_MEMBER_START = 'DELETE_MEMBER_START';
export const DELETE_MEMBER_SUCCESS = 'DELETE_MEMBER_SUCCESS';
export const DELETE_MEMBER_FAILED = 'DELETE_MEMBER_FAILED';
export const DELETE_MEMBER_RESET = 'DELETE_MEMBER_RESET';

// Action Creators: Get All Members
const getAllMembersStart = (payload) => ({ type: GET_ALL_MEMBERS_START, payload });
const getAllMembersSuccess = (payload) => ({ type: GET_ALL_MEMBERS_SUCCESS, payload });
const getAllMembersFailed = (payload) => ({ type: GET_ALL_MEMBERS_FAILED, payload });
const getAllMembersReset = () => ({ type: GET_ALL_MEMBERS_RESET });

// Action Creators: Get One Member
const getOneMemberStart = (payload) => ({ type: GET_ONE_MEMBER_START, payload });
const getOneMemberSuccess = (payload) => ({ type: GET_ONE_MEMBER_SUCCESS, payload });
const getOneMemberFailed = (payload) => ({ type: GET_ONE_MEMBER_FAILED, payload });
const getOneMemberReset = () => ({ type: GET_ONE_MEMBER_RESET });

// Action Creators: Create Member
const createMemberStart = (payload) => ({ type: CREATE_MEMBER_START, payload });
const createMemberSuccess = (payload) => ({ type: CREATE_MEMBER_SUCCESS, payload });
const createMemberFailed = (payload) => ({ type: CREATE_MEMBER_FAILED, payload });
const createMemberReset = () => ({ type: CREATE_MEMBER_RESET });

// Action Creators: Delete One Member.
const deleteMemberStart = (payload) => ({ type: DELETE_MEMBER_START, payload });
const deleteMemberSuccess = (payload) => ({ type: DELETE_MEMBER_SUCCESS, payload });
const deleteMemberFailed = (payload) => ({ type: DELETE_MEMBER_FAILED, payload });
const deleteMemberReset = () => ({ type: DELETE_MEMBER_RESET });

// Actions: Get All Members
export const getAllMembers = (payload, options = {}) => {
  const { page, limit, pagination, workshop, survey, deleted } = payload;
  const {} = options;
  return async (dispatch) => {
    try {
      dispatchLoading(dispatch, getAllMembersStart);
      const result = await ApiClient.get('/members', {
        params: { pagination, page, limit, workshop, survey, deleted: 0 },
      });
      if (result.data?.success) {
        const { members } = result.data?.data;
        dispatchSuccess(dispatch, getAllMembersSuccess, members, membersName);
      } else {
        const errorMessage = result.data?.error || 'Something went wrong!';
        dispatchError(dispatch, getAllMembersFailed, errorMessage);
        showSnackbar(dispatch, errorMessage, 'error');
      }
    } catch (error) {
      const errorMessage = getAxiosError(error);
      dispatchError(dispatch, getAllMembersFailed, errorMessage);
      showSnackbar(dispatch, errorMessage, 'error');
    }
  };
};

// Actions: Clear Get All Members
export const clearGetAllMembers = () => getAllMembersReset();

// Actions: Get One Member
export const getOneMember = (payload, options = {}) => {
  const { memberId } = payload;
  const {} = options;
  return async (dispatch) => {
    try {
      dispatchLoading(dispatch, getOneMemberStart);
      const result = await ApiClient.get(`/members/${memberId}`);
      if (result.data?.success) {
        const { member } = result.data?.data;
        dispatchSuccess(dispatch, getOneMemberSuccess, member, memberName);
      } else {
        const errorMessage = result.data?.error || 'Something went wrong!';
        dispatchError(dispatch, getOneMemberFailed, errorMessage);
        showSnackbar(dispatch, errorMessage, 'error');
      }
    } catch (error) {
      const errorMessage = getAxiosError(error);
      dispatchError(dispatch, getOneMemberFailed, errorMessage);
      showSnackbar(dispatch, errorMessage, 'error');
    }
  };
};

// Actions: Clear Get One Member
export const clearGetOneMember = () => getOneMemberReset();

// Actions: Create Member
export const createMember = (payload, options = {}) => {
  const {
    uuid,
    workshop,
    survey,
    member,
    answer,
    answers,
    acceptedTeam,
    acceptanceCriteriaResult,
    application,
  } = payload;
  const { shouldRedirect } = options;
  return async (dispatch) => {
    try {
      dispatchLoading(dispatch, createMemberStart);
      const result = await ApiClient.post('/members', {
        uuid,
        workshop,
        survey,
        member,
        answer,
        answers,
        acceptedTeam,
        acceptanceCriteriaResult,
        application,
      });
      if (result.data?.success) {
        const { member } = result.data.data;
        dispatchSuccess(dispatch, createMemberSuccess, member, memberName);
        shouldRedirect
          ? showSnackbar(dispatch, 'Members accepted successfully!', 'success')
          : showSnackbar(dispatch, 'Member accepted successfully!', 'success');
      } else {
        const errorMessage = result.data?.error || 'Something went wrong!';
        dispatchError(dispatch, createMemberFailed, errorMessage);
        showSnackbar(dispatch, errorMessage, 'error');
      }
    } catch (error) {
      const errorMessage = getAxiosError(error);
      dispatchError(dispatch, createMemberFailed, errorMessage);
      showSnackbar(dispatch, errorMessage, 'error');
    }
  };
};

// Actions: Clear Create Member
export const clearCreateMember = () => createMemberReset();

// Actions: Delete Member
export const deleteMember = (payload, options = {}) => {
  const { memberId } = payload;
  return async (dispatch) => {
    try {
      dispatchLoading(dispatch, deleteMemberStart);
      const result = await ApiClient.delete(`/members/${memberId}`);
      if (result.data?.success) {
        const { member } = result.data.data;
        dispatchSuccess(dispatch, deleteMemberSuccess, member, memberName);
        showSnackbar(dispatch, 'Member deleted successfully!', 'success');
      } else {
        const errorMessage = result.data?.error || 'Something went wrong!';
        dispatchError(dispatch, deleteMemberFailed, errorMessage);
        showSnackbar(dispatch, errorMessage, 'error');
      }
    } catch (error) {
      const errorMessage = getAxiosError(error);
      dispatchError(dispatch, deleteMemberFailed, errorMessage);
      showSnackbar(dispatch, errorMessage, 'error');
    }
  };
};

// Actions: Clear Deleted Member.
export const clearDeletedMember = () => deleteMemberReset();
