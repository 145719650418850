// Imports: local files.
import {
  GET_APPLICATIONS_START,
  GET_APPLICATIONS_SUCCESS,
  GET_APPLICATIONS_FAILED,
  GET_APPLICATIONS_RESET,
  GET_APPLICATION_START,
  GET_APPLICATION_SUCCESS,
  GET_APPLICATION_FAILED,
  GET_APPLICATION_RESET,
  CREATE_APPLICATION_START,
  CREATE_APPLICATION_SUCCESS,
  CREATE_APPLICATION_FAILED,
  CREATE_APPLICATION_RESET,
  UPDATE_APPLICATION_START,
  UPDATE_APPLICATION_SUCCESS,
  UPDATE_APPLICATION_FAILED,
  UPDATE_APPLICATION_RESET,
  DELETE_APPLICATION_START,
  DELETE_APPLICATION_SUCCESS,
  DELETE_APPLICATION_FAILED,
  DELETE_APPLICATION_RESET,
} from '../actions';

const initialLoadingState = {
  loading: false,
  success: false,
  data: null,
  error: false,
  errorMessage: null,
};
const initialState = {
  getAll: initialLoadingState,
  getOne: initialLoadingState,
  create: initialLoadingState,
  update: initialLoadingState,
  deleteOne: initialLoadingState,
};

const applicationsReducer = (state = initialState, action) => {
  switch (action.type) {
    /**
     * =======================================================================
     * Get All Applications
     * =======================================================================
     */
    case GET_APPLICATIONS_START:
      return { ...state, getAll: { ...action.payload } };
    case GET_APPLICATIONS_SUCCESS:
      return { ...state, getAll: { ...action.payload } };
    case GET_APPLICATIONS_FAILED:
      return { ...state, getAll: { ...action.payload } };
    case GET_APPLICATIONS_RESET:
      return { ...state, getAll: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Get One Application
     * =======================================================================
     */
    case GET_APPLICATION_START:
      return { ...state, getOne: { ...action.payload } };
    case GET_APPLICATION_SUCCESS:
      return { ...state, getOne: { ...action.payload } };
    case GET_APPLICATION_FAILED:
      return { ...state, getOne: { ...action.payload } };
    case GET_APPLICATION_RESET:
      return { ...state, getOne: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Create One Application
     * =======================================================================
     */
    case CREATE_APPLICATION_START:
      return { ...state, create: { ...action.payload } };
    case CREATE_APPLICATION_SUCCESS:
      return { ...state, create: { ...action.payload } };
    case CREATE_APPLICATION_FAILED:
      return { ...state, create: { ...action.payload } };
    case CREATE_APPLICATION_RESET:
      return { ...state, create: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Create One Application
     * =======================================================================
     */
    case UPDATE_APPLICATION_START:
      return { ...state, update: { ...action.payload } };
    case UPDATE_APPLICATION_SUCCESS:
      return { ...state, update: { ...action.payload } };
    case UPDATE_APPLICATION_FAILED:
      return { ...state, update: { ...action.payload } };
    case UPDATE_APPLICATION_RESET:
      return { ...state, update: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Delete One Application
     * =======================================================================
     */
    case DELETE_APPLICATION_START:
      return { ...state, deleteOne: { ...action.payload } };
    case DELETE_APPLICATION_SUCCESS:
      return { ...state, deleteOne: { ...action.payload } };
    case DELETE_APPLICATION_FAILED:
      return { ...state, deleteOne: { ...action.payload } };
    case DELETE_APPLICATION_RESET:
      return { ...state, deleteOne: { ...initialLoadingState } };
    default:
      return state;
  }
};

export default applicationsReducer;
