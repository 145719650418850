// Imports: local files.
import {
  GET_WORKSHOPS_START,
  GET_WORKSHOPS_SUCCESS,
  GET_WORKSHOPS_FAILED,
  GET_WORKSHOPS_RESET,
  GET_WORKSHOP_SURVEYS_START,
  GET_WORKSHOP_SURVEYS_SUCCESS,
  GET_WORKSHOP_SURVEYS_FAILED,
  GET_WORKSHOP_SURVEYS_RESET,
  GET_SURVEY_QUESTIONS_START,
  GET_SURVEY_QUESTIONS_SUCCESS,
  GET_SURVEY_QUESTIONS_FAILED,
  GET_SURVEY_QUESTIONS_RESET,
  GET_WORKSHOP_TYPE_QUESTIONS_START,
  GET_WORKSHOP_TYPE_QUESTIONS_SUCCESS,
  GET_WORKSHOP_TYPE_QUESTIONS_FAILED,
  GET_WORKSHOP_TYPE_QUESTIONS_RESET,
  GET_MEMBER_QUESTIONS_START,
  GET_MEMBER_QUESTIONS_SUCCESS,
  GET_MEMBER_QUESTIONS_FAILED,
  GET_MEMBER_QUESTIONS_RESET,
  GET_WORKSHOP_START,
  GET_WORKSHOP_SUCCESS,
  GET_WORKSHOP_FAILED,
  GET_WORKSHOP_RESET,
  CREATE_WORKSHOP_START,
  CREATE_WORKSHOP_SUCCESS,
  CREATE_WORKSHOP_FAILED,
  CREATE_WORKSHOP_RESET,
  UPDATE_WORKSHOP_START,
  UPDATE_WORKSHOP_SUCCESS,
  UPDATE_WORKSHOP_FAILED,
  UPDATE_WORKSHOP_RESET,
  DELETE_WORKSHOP_START,
  DELETE_WORKSHOP_SUCCESS,
  DELETE_WORKSHOP_FAILED,
  DELETE_WORKSHOP_RESET,
} from '../actions';

const initialLoadingState = {
  loading: false,
  success: false,
  data: null,
  error: false,
  errorMessage: null,
};

const initialState = {
  getAll: initialLoadingState,
  getAllSurveys: initialLoadingState,
  getApplicationQuestions: initialLoadingState,
  getPreQuestions: initialLoadingState,
  getFirstPostQuestions: initialLoadingState,
  getSecondPostQuestions: initialLoadingState,
  getThirdPostQuestions: initialLoadingState,
  getFourthPostQuestions: initialLoadingState,
  getAcceptanceCriteriaQuestions: initialLoadingState,
  getWorkshopTypeQuestions: {
    loading: false,
    success: false,
    data: null,
    error: false,
    errorMessage: null,
    dataDocs: [],
  },
  getMemberQuestions: initialLoadingState,
  getOne: initialLoadingState,
  create: initialLoadingState,
  update: initialLoadingState,
  deleteOne: initialLoadingState,
};

const workshopReducer = (state = initialState, action) => {
  switch (action.type) {
    /**
     * =======================================================================
     * Get All Workshops
     * =======================================================================
     */
    case GET_WORKSHOPS_START:
      return { ...state, getAll: { ...action.payload } };
    case GET_WORKSHOPS_SUCCESS:
      return { ...state, getAll: { ...action.payload } };
    case GET_WORKSHOPS_FAILED:
      return { ...state, getAll: { ...action.payload } };
    case GET_WORKSHOPS_RESET:
      return { ...state, getAll: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Get All Workshop Surveys
     * =======================================================================
     */
    case GET_WORKSHOP_SURVEYS_START:
      return { ...state, getAllSurveys: { ...action.payload } };
    case GET_WORKSHOP_SURVEYS_SUCCESS:
      return { ...state, getAllSurveys: { ...action.payload } };
    case GET_WORKSHOP_SURVEYS_FAILED:
      return { ...state, getAllSurveys: { ...action.payload } };
    case GET_WORKSHOP_SURVEYS_RESET:
      return { ...state, getAllSurveys: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Get All Survey Questions
     * =======================================================================
     */
    case GET_SURVEY_QUESTIONS_START:
      return { ...state, getSurveyQuestions: { ...action.payload } };
    case GET_SURVEY_QUESTIONS_SUCCESS:
      if (action.payload && action.payload.data && action.payload.data.type === 'APPLICATION') {
        return { ...state, getApplicationQuestions: { ...action.payload } };
      } else if (action.payload && action.payload.data && action.payload.data.type === 'PRE') {
        return { ...state, getPreQuestions: { ...action.payload } };
      } else if (action.payload && action.payload.data && action.payload.data.type === 'FIRST_POST') {
        return { ...state, getFirstPostQuestions: { ...action.payload } };
      } else if (action.payload && action.payload.data && action.payload.data.type === 'SECOND_POST') {
        return { ...state, getSecondPostQuestions: { ...action.payload } };
      } else if (action.payload && action.payload.data && action.payload.data.type === 'THIRD_POST') {
        return { ...state, getThirdPostQuestions: { ...action.payload } };
      } else if (action.payload && action.payload.data && action.payload.data.type === 'FOURTH_POST') {
        return { ...state, getFourthPostQuestions: { ...action.payload } };
      } else if (action.payload && action.payload.data && action.payload.data.type === 'ACCEPTANCE_CRITERIA') {
        return { ...state, getAcceptanceCriteriaQuestions: { ...action.payload } };
      }
    // return { ...state, getSurveyQuestions: { ...action.payload } };
    case GET_SURVEY_QUESTIONS_FAILED:
      return { ...state, getSurveyQuestions: { ...action.payload } };
    case GET_SURVEY_QUESTIONS_RESET:
      return {
        ...state,
        getApplicationQuestions: { ...initialLoadingState },
        getPreQuestions: { ...initialLoadingState },
        getFirstPostQuestions: { ...initialLoadingState },
        getSecondPostQuestions: { ...initialLoadingState },
        getThirdPostQuestions: { ...initialLoadingState },
        getFourthPostQuestions: { ...initialLoadingState },
        getAcceptanceCriteriaQuestions: { ...initialLoadingState },
      };
    /**
     * =======================================================================
     * Get All Workshop Type Questions
     * =======================================================================
     */
    case GET_WORKSHOP_TYPE_QUESTIONS_START:
      return {
        ...state,
        getWorkshopTypeQuestions: { ...action.payload, dataDocs: state.getWorkshopTypeQuestions.dataDocs },
      };
    case GET_WORKSHOP_TYPE_QUESTIONS_SUCCESS:
      return {
        ...state,
        getWorkshopTypeQuestions: {
          ...action.payload,
          dataDocs: state.getWorkshopTypeQuestions.dataDocs.concat(action?.payload?.data?.questions?.docs),
        },
      };
    case GET_WORKSHOP_TYPE_QUESTIONS_FAILED:
      return { ...state, getWorkshopTypeQuestions: { ...action.payload, dataDocs: [] } };
    case GET_WORKSHOP_TYPE_QUESTIONS_RESET:
      return {
        ...state,
        getWorkshopTypeQuestions: {
          loading: false,
          success: false,
          data: null,
          error: false,
          errorMessage: null,
          dataDocs: [],
        },
      };
    /**
     * =======================================================================
     * Get Member Questions
     * =======================================================================
     */
    case GET_MEMBER_QUESTIONS_START:
      return { ...state, getMemberQuestions: { ...action.payload } };
    case GET_MEMBER_QUESTIONS_SUCCESS:
      return { ...state, getMemberQuestions: { ...action.payload } };
    case GET_MEMBER_QUESTIONS_FAILED:
      return { ...state, getMemberQuestions: { ...action.payload } };
    case GET_MEMBER_QUESTIONS_RESET:
      return { ...state, getMemberQuestions: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Get One Workshop
     * =======================================================================
     */
    case GET_WORKSHOP_START:
      return { ...state, getOne: { ...action.payload } };
    case GET_WORKSHOP_SUCCESS:
      return { ...state, getOne: { ...action.payload } };
    case GET_WORKSHOP_FAILED:
      return { ...state, getOne: { ...action.payload } };
    case GET_WORKSHOP_RESET:
      return { ...state, getOne: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Create One Workshop
     * =======================================================================
     */
    case CREATE_WORKSHOP_START:
      return { ...state, create: { ...action.payload } };
    case CREATE_WORKSHOP_SUCCESS:
      return { ...state, create: { ...action.payload } };
    case CREATE_WORKSHOP_FAILED:
      return { ...state, create: { ...action.payload } };
    case CREATE_WORKSHOP_RESET:
      return { ...state, create: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Create One Workshop
     * =======================================================================
     */
    case UPDATE_WORKSHOP_START:
      return { ...state, update: { ...action.payload } };
    case UPDATE_WORKSHOP_SUCCESS:
      return { ...state, update: { ...action.payload } };
    case UPDATE_WORKSHOP_FAILED:
      return { ...state, update: { ...action.payload } };
    case UPDATE_WORKSHOP_RESET:
      return { ...state, update: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Delete One Workshop
     * =======================================================================
     */
    case DELETE_WORKSHOP_START:
      return { ...state, deleteOne: { ...action.payload } };
    case DELETE_WORKSHOP_SUCCESS:
      return { ...state, deleteOne: { ...action.payload } };
    case DELETE_WORKSHOP_FAILED:
      return { ...state, deleteOne: { ...action.payload } };
    case DELETE_WORKSHOP_RESET:
      return { ...state, deleteOne: { ...initialLoadingState } };
    default:
      return state;
  }
};

export default workshopReducer;
