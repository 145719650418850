// Imports: local files.
import {
  COUNTS_START,
  COUNTS_SUCCESS,
  COUNTS_FAILED,
  COUNTS_RESET,
  USERS_BY_ROLE_START,
  USERS_BY_ROLE_SUCCESS,
  USERS_BY_ROLE_FAILED,
  USERS_BY_ROLE_RESET,
  WORKSHOPS_BY_TYPE_START,
  WORKSHOPS_BY_TYPE_SUCCESS,
  WORKSHOPS_BY_TYPE_FAILED,
  WORKSHOPS_BY_TYPE_RESET,
  QUESTION_REPORTS_START,
  QUESTION_REPORTS_SUCCESS,
  QUESTION_REPORTS_FAILED,
  QUESTION_REPORTS_RESET,
  CHECKBOX_REPORTS_START,
  CHECKBOX_REPORTS_SUCCESS,
  CHECKBOX_REPORTS_FAILED,
  CHECKBOX_REPORTS_RESET,
  MATRIX_REPORTS_START,
  MATRIX_REPORTS_SUCCESS,
  MATRIX_REPORTS_FAILED,
  MATRIX_REPORTS_RESET,
} from '../actions';

const initialLoadingState = {
  loading: false,
  success: false,
  data: null,
  error: false,
  errorMessage: null,
};
const initialState = {
  counts: initialLoadingState,
  usersByRole: initialLoadingState,
  workshopsByType: initialLoadingState,
  questions: initialLoadingState,
  checkbox: initialLoadingState,
  matrix: initialLoadingState,
};

const reportsReducer = (state = initialState, action) => {
  switch (action.type) {
    /**
     * =======================================================================
     * Counts
     * =======================================================================
     */
    case COUNTS_START:
      return { ...state, counts: { ...action.payload } };
    case COUNTS_SUCCESS:
      return { ...state, counts: { ...action.payload } };
    case COUNTS_FAILED:
      return { ...state, counts: { ...action.payload } };
    case COUNTS_RESET:
      return { ...state, counts: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Users by role
     * =======================================================================
     */
    case USERS_BY_ROLE_START:
      return { ...state, usersByRole: { ...action.payload } };
    case USERS_BY_ROLE_SUCCESS:
      return { ...state, usersByRole: { ...action.payload } };
    case USERS_BY_ROLE_FAILED:
      return { ...state, usersByRole: { ...action.payload } };
    case USERS_BY_ROLE_RESET:
      return { ...state, usersByRole: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Workshops By Type
     * =======================================================================
     */
    case WORKSHOPS_BY_TYPE_START:
      return { ...state, workshopsByType: { ...action.payload } };
    case WORKSHOPS_BY_TYPE_SUCCESS:
      return { ...state, workshopsByType: { ...action.payload } };
    case WORKSHOPS_BY_TYPE_FAILED:
      return { ...state, workshopsByType: { ...action.payload } };
    case WORKSHOPS_BY_TYPE_RESET:
      return { ...state, workshopsByType: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Question Reports
     * =======================================================================
     */
    case QUESTION_REPORTS_START:
      return { ...state, questions: { ...action.payload } };
    case QUESTION_REPORTS_SUCCESS:
      return { ...state, questions: { ...action.payload } };
    case QUESTION_REPORTS_FAILED:
      return { ...state, questions: { ...action.payload } };
    case QUESTION_REPORTS_RESET:
      return { ...state, questions: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Checkbox Reports
     * =======================================================================
     */
    case CHECKBOX_REPORTS_START:
      return { ...state, checkbox: { ...action.payload } };
    case CHECKBOX_REPORTS_SUCCESS:
      return { ...state, checkbox: { ...action.payload } };
    case CHECKBOX_REPORTS_FAILED:
      return { ...state, checkbox: { ...action.payload } };
    case CHECKBOX_REPORTS_RESET:
      return { ...state, checkbox: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Matrix Reports
     * =======================================================================
     */
    case MATRIX_REPORTS_START:
      return { ...state, matrix: { ...action.payload } };
    case MATRIX_REPORTS_SUCCESS:
      return { ...state, matrix: { ...action.payload } };
    case MATRIX_REPORTS_FAILED:
      return { ...state, matrix: { ...action.payload } };
    case MATRIX_REPORTS_RESET:
      return { ...state, matrix: { ...initialLoadingState } };
    default:
      return state;
  }
};

export default reportsReducer;
