// Imports: local files.
import ApiClient from '../../services/ApiClient';
import { showSnackbar, dispatchLoading, dispatchSuccess, dispatchError, getAxiosError } from '../../utils/redux';

// Name of property to be used in reducer data.
const workshoptypesName = 'workshopTypes';
const workshoptypeName = 'workshopType';

// Actions Types: Get All Workshop Types.
export const GET_WORKSHOP_TYPES_START = 'GET_WORKSHOP_TYPES_START';
export const GET_WORKSHOP_TYPES_SUCCESS = 'GET_WORKSHOP_TYPES_SUCCESS';
export const GET_WORKSHOP_TYPES_FAILED = 'GET_WORKSHOP_TYPES_FAILED';
export const GET_WORKSHOP_TYPES_RESET = 'GET_WORKSHOP_TYPES_RESET';

// Actions Types: Get One User.
export const GET_WORKSHOP_TYPE_START = 'GET_WORKSHOP_TYPE_START';
export const GET_WORKSHOP_TYPE_SUCCESS = 'GET_WORKSHOP_TYPE_SUCCESS';
export const GET_WORKSHOP_TYPE_FAILED = 'GET_WORKSHOP_TYPE_FAILED';
export const GET_WORKSHOP_TYPE_RESET = 'GET_WORKSHOP_TYPE_RESET';

// Actions Types: Create Workshop Type.
export const CREATE_WORKSHOP_TYPE_START = 'CREATE_WORKSHOP_TYPE_START';
export const CREATE_WORKSHOP_TYPE_SUCCESS = 'CREATE_WORKSHOP_TYPE_SUCCESS';
export const CREATE_WORKSHOP_TYPE_FAILED = 'CREATE_WORKSHOP_TYPE_FAILED';
export const CREATE_WORKSHOP_TYPE_RESET = 'CREATE_WORKSHOP_TYPE_RESET';

// Action Types: Duplicate Workshop Type.
export const DUPLICATE_WORKSHOP_TYPE_SUCCESS = 'DUPLICATE_WORKSHOP_TYPE_SUCCESS';

// Actions Types: Update Workshop Type.
export const UPDATE_WORKSHOP_TYPE_START = 'UPDATE_WORKSHOP_TYPE_START';
export const UPDATE_WORKSHOP_TYPE_SUCCESS = 'UPDATE_WORKSHOP_TYPE_SUCCESS';
export const UPDATE_WORKSHOP_TYPE_FAILED = 'UPDATE_WORKSHOP_TYPE_FAILED';
export const UPDATE_WORKSHOP_TYPE_RESET = 'UPDATE_WORKSHOP_TYPE_RESET';

// Actions Types: Delete Workshop Type.
export const DELETE_WORKSHOP_TYPE_START = 'DELETE_WORKSHOP_TYPE_START';
export const DELETE_WORKSHOP_TYPE_SUCCESS = 'DELETE_WORKSHOP_TYPE_SUCCESS';
export const DELETE_WORKSHOP_TYPE_FAILED = 'DELETE_WORKSHOP_TYPE_FAILED';
export const DELETE_WORKSHOP_TYPE_RESET = 'DELETE_WORKSHOP_TYPE_RESET';

// Action Creators: Get All Workshop Types.
const getWorkshopTypesStart = (payload) => ({ type: GET_WORKSHOP_TYPES_START, payload });
const getWorkshopTypesSuccess = (payload) => ({ type: GET_WORKSHOP_TYPES_SUCCESS, payload });
const getWorkshopTypesFailed = (payload) => ({ type: GET_WORKSHOP_TYPES_FAILED, payload });
const getWorkshopTypesReset = () => ({ type: GET_WORKSHOP_TYPES_RESET });

// Action Creators: Get One Workshop Type.
const getWorkshopTypeStart = (payload) => ({ type: GET_WORKSHOP_TYPE_START, payload });
const getWorkshopTypeSuccess = (payload) => ({ type: GET_WORKSHOP_TYPE_SUCCESS, payload });
const getWorkshopTypeFailed = (payload) => ({ type: GET_WORKSHOP_TYPE_FAILED, payload });
const getWorkshopTypeReset = () => ({ type: GET_WORKSHOP_TYPE_RESET });

// Action Creators: Create Workshop Type.
const createWorkshopTypeStart = (payload) => ({ type: CREATE_WORKSHOP_TYPE_START, payload });
const createWorkshopTypeSuccess = (payload) => ({ type: CREATE_WORKSHOP_TYPE_SUCCESS, payload });
const createWorkshopTypeFailed = (payload) => ({ type: CREATE_WORKSHOP_TYPE_FAILED, payload });
const createWorkshopTypeReset = () => ({ type: CREATE_WORKSHOP_TYPE_RESET });

// Action Creators: Duplicate Workshop Type.
const duplicateWorkshopTypeSuccess = (payload) => ({ type: DUPLICATE_WORKSHOP_TYPE_SUCCESS, payload });

// Action Creators: Update Workshop Type.
const updateWorkshopTypeStart = (payload) => ({ type: UPDATE_WORKSHOP_TYPE_START, payload });
const updateWorkshopTypeSuccess = (payload) => ({ type: UPDATE_WORKSHOP_TYPE_SUCCESS, payload });
const updateWorkshopTypeFailed = (payload) => ({ type: UPDATE_WORKSHOP_TYPE_FAILED, payload });
const updateWorkshopTypeReset = () => ({ type: UPDATE_WORKSHOP_TYPE_RESET });

// Action Creators: Delete Workshop Type.
const deleteWorkshopTypeStart = (payload) => ({ type: DELETE_WORKSHOP_TYPE_START, payload });
const deleteWorkshopTypeSuccess = (payload) => ({ type: DELETE_WORKSHOP_TYPE_SUCCESS, payload });
const deleteWorkshopTypeFailed = (payload) => ({ type: DELETE_WORKSHOP_TYPE_FAILED, payload });
const deleteWorkshopTypeReset = () => ({ type: DELETE_WORKSHOP_TYPE_RESET });

// Actions: Get All Workshop Types.
export const getAllWorkshopTypes = (payload, options = {}) => {
  const { page, limit, pagination, isOther, availability, deleted, organisation } = payload;
  const {} = options;
  return async (dispatch) => {
    try {
      dispatchLoading(dispatch, getWorkshopTypesStart);

      const result = await ApiClient.get('/workshoptypes', {
        params: { page, limit, pagination, isOther, availability, deleted, organisation },
      });
      if (result.data?.success) {
        const { workshopTypes } = result.data?.data;
        dispatchSuccess(dispatch, getWorkshopTypesSuccess, workshopTypes, workshoptypesName);
      } else {
        const errorMessage = result.data?.error || 'Something went wrong!';
        dispatchError(dispatch, getWorkshopTypesFailed, errorMessage);
        showSnackbar(dispatch, errorMessage, 'error');
      }
    } catch (error) {
      const errorMessage = getAxiosError(error);
      dispatchError(dispatch, getWorkshopTypesFailed, errorMessage);
      showSnackbar(dispatch, errorMessage, 'error');
    }
  };
};

// Actions: Clear All WorkshopTypes.
export const clearAllWorkshopTypes = () => getWorkshopTypesReset();

// Actions: Get One Workshop Type.
export const getOneWorkshopType = (payload, options = {}) => {
  const { workshopTypeId } = payload;
  const {} = options;
  return async (dispatch) => {
    try {
      dispatchLoading(dispatch, getWorkshopTypeStart);

      const result = await ApiClient.get(`/workshoptypes/${workshopTypeId}`);
      if (result.data?.success) {
        const { workshopType } = result.data?.data;
        dispatchSuccess(dispatch, getWorkshopTypeSuccess, workshopType, workshoptypeName);
      } else {
        const errorMessage = result.data?.error || 'Something went wrong!';
        dispatchError(dispatch, getWorkshopTypeFailed, errorMessage);
        showSnackbar(dispatch, errorMessage, 'error');
      }
    } catch (error) {
      const errorMessage = getAxiosError(error);
      dispatchError(dispatch, getWorkshopTypeFailed, errorMessage);
      showSnackbar(dispatch, errorMessage, 'error');
    }
  };
};

// Actions: Clear One Workshop Type.
export const clearOneWorkshopType = () => getWorkshopTypeReset();

// Actions: Create New Workshop Type.
export const createWorkshopType = (payload, options = {}) => {
  const {
    name,
    description,
    applicationJson,
    preJson,
    firstPostJson,
    secondPostJson,
    thirdPostJson,
    fourthPostJson,
    acceptanceCriteriaJson,
  } = payload;
  const { recall, duplicate } = options; // Added 'duplicate' option
  return async (dispatch) => {
    try {
      dispatchLoading(dispatch, createWorkshopTypeStart);

      const result = await ApiClient.post(`/workshoptypes`, {
        name,
        description,
        applicationJson,
        preJson,
        firstPostJson,
        secondPostJson,
        thirdPostJson,
        fourthPostJson,
        acceptanceCriteriaJson,
      });
      if (result.data?.success) {
        const { workshopType } = result.data?.data;
        if (duplicate) {
          dispatchSuccess(dispatch, duplicateWorkshopTypeSuccess, workshopType, workshoptypeName);
          showSnackbar(dispatch, 'Workshop Type duplicated successfully!', 'success');
        } else {
          dispatchSuccess(dispatch, createWorkshopTypeSuccess, workshopType, workshoptypeName);
          showSnackbar(dispatch, 'Workshop Type created successfully!', 'success');
        }
        if (recall) recall();
      } else {
        const errorMessage = result.data?.error || 'Something went wrong!';
        dispatchError(dispatch, createWorkshopTypeFailed, errorMessage);
        showSnackbar(dispatch, errorMessage, 'error');
      }
    } catch (error) {
      const errorMessage = getAxiosError(error);
      dispatchError(dispatch, createWorkshopTypeFailed, errorMessage);
      showSnackbar(dispatch, errorMessage, 'error');
    }
  };
};

// Actions: Clear Created Workshop Type.
export const clearCreatedWorkshopType = () => createWorkshopTypeReset();

// Actions: Update Workshop Type.
export const updateWorkshopType = (payload, options = {}) => {
  const {
    workshopTypeId,
    name,
    description,
    applicationJson,
    preJson,
    firstPostJson,
    secondPostJson,
    thirdPostJson,
    fourthPostJson,
    acceptanceCriteriaJson,
  } = payload;
  const {} = options;
  return async (dispatch) => {
    try {
      dispatchLoading(dispatch, updateWorkshopTypeStart);

      const result = await ApiClient.put(`/workshoptypes/${workshopTypeId}`, {
        name,
        description,
        applicationJson,
        preJson,
        firstPostJson,
        secondPostJson,
        thirdPostJson,
        fourthPostJson,
        acceptanceCriteriaJson,
      });
      if (result.data?.success) {
        const { workshopType } = result.data?.data;
        dispatchSuccess(dispatch, updateWorkshopTypeSuccess, workshopType, workshoptypeName);
        showSnackbar(dispatch, 'Workshop Type updated successfully!', 'success');
      } else {
        const errorMessage = result.data?.error || 'Something went wrong!';
        dispatchError(dispatch, updateWorkshopTypeFailed, errorMessage);
        showSnackbar(dispatch, errorMessage, 'error');
      }
    } catch (error) {
      const errorMessage = getAxiosError(error);
      dispatchError(dispatch, updateWorkshopTypeFailed, errorMessage);
      showSnackbar(dispatch, errorMessage, 'error');
    }
  };
};

// Actions: Clear Updated Workshop Type.
export const clearUpdatedWorkshopType = () => updateWorkshopTypeReset();

// Actions: Delete One User.
export const deleteOneWorkshopType = (payload, options = {}) => {
  const { workshopTypeId } = payload;
  const {} = options;
  return async (dispatch) => {
    try {
      dispatchLoading(dispatch, deleteWorkshopTypeStart);

      const result = await ApiClient.delete(`/workshoptypes/${workshopTypeId}`);
      if (result.data?.success) {
        const { workshopType } = result.data?.data;
        dispatchSuccess(dispatch, deleteWorkshopTypeSuccess, workshopType, workshoptypeName);
        showSnackbar(dispatch, 'Workshop Type deleted successfully!', 'success');
      } else {
        const errorMessage = result.data?.error || 'Something went wrong!';
        dispatchError(dispatch, deleteWorkshopTypeFailed, errorMessage);
        showSnackbar(dispatch, errorMessage, 'error');
      }
    } catch (error) {
      const errorMessage = getAxiosError(error);
      dispatchError(dispatch, deleteWorkshopTypeFailed, errorMessage);
      showSnackbar(dispatch, errorMessage, 'error');
    }
  };
};

// Actions: Clear Deleted Workshop Type.
export const clearDeletedWorkshopType = () => deleteWorkshopTypeReset();
