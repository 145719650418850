// Imports: local files.
import {
  LOGIN_START,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  LOGIN_RESET,
  LOGOUT_SUCCESS,
  LOGOUT_FAILED,
  SAVE_START,
  SAVE_SUCCESS,
  SAVE_FAILED,
  SAVE_RESET,
  FORGOT_START,
  FORGOT_SUCCESS,
  FORGOT_FAILED,
  FORGOT_RESET,
  RESET_START,
  RESET_SUCCESS,
  RESET_FAILED,
  RESET_RESET,
} from '../actions';

const initialLoadingState = {
  loading: false,
  success: false,
  data: null,
  error: false,
  errorMessage: null,
};
const initialState = {
  login: initialLoadingState,
  currentUser: initialLoadingState,
  forgotPassword: initialLoadingState,
  resetPassword: initialLoadingState,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    /**
     * =======================================================================
     * Login User
     * =======================================================================
     */
    case LOGIN_START:
      return { ...state, login: { ...action.payload } };
    case LOGIN_SUCCESS:
      return { ...state, login: { ...action.payload } };
    case LOGIN_FAILED:
      return { ...state, login: { ...action.payload } };
    case LOGIN_RESET:
      return { ...state, login: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Logout User
     * =======================================================================
     */
    case LOGOUT_SUCCESS:
      return { ...state };
    case LOGOUT_FAILED:
      return { ...state };
    /**
     * =======================================================================
     * Save User
     * =======================================================================
     */
    case SAVE_START:
      return { ...state, currentUser: { ...action.payload } };
    case SAVE_SUCCESS:
      return { ...state, currentUser: { ...action.payload } };
    case SAVE_FAILED:
      return { ...state, currentUser: { ...action.payload } };
    case SAVE_RESET:
      return { ...state, currentUser: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Forgot Password
     * =======================================================================
     */
    case FORGOT_START:
      return { ...state, forgotPassword: { ...action.payload } };
    case FORGOT_SUCCESS:
      return { ...state, forgotPassword: { ...action.payload } };
    case FORGOT_FAILED:
      return { ...state, forgotPassword: { ...action.payload } };
    case FORGOT_RESET:
      return { ...state, forgotPassword: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Reset Password
     * =======================================================================
     */
    case RESET_START:
      return { ...state, resetPassword: { ...action.payload } };
    case RESET_SUCCESS:
      return { ...state, resetPassword: { ...action.payload } };
    case RESET_FAILED:
      return { ...state, resetPassword: { ...action.payload } };
    case RESET_RESET:
      return { ...state, resetPassword: { ...initialLoadingState } };
    default:
      return state;
  }
};

export default authReducer;
