export const showSnackbar = (dispatch, message, type) => {
  dispatch({
    type: '@snackbar/SNACKBAR_OPEN',
    open: true,
    message: message,
    variant: 'alert',
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'right',
    },
    alertSeverity: type,
    close: true,
  });
};

export const dispatchLoading = (dispatch, action) => {
  dispatch(action({ loading: true, success: false, data: null, error: false, errorMessage: null }));
};
export const dispatchSuccess = (dispatch, action, data, propName) => {
  dispatch(action({ loading: false, success: true, data: { [propName]: data }, error: false, errorMessage: null }));
};
export const dispatchError = (dispatch, action, errorMessage) => {
  dispatch(action({ loading: false, success: false, data: null, error: true, errorMessage: errorMessage }));
};
export const getAxiosError = (error) => {
  return error.response && error.response.data && error.response.data.error
    ? error.response.data.error
    : 'Something went wrong!';
};
