import PropTypes from 'prop-types';
import jwtDecode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

// project imports
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }) => {
  const navigate = useNavigate();
  let isLoggedIn = false;

  const token = localStorage.getItem('unicef-token');
  if (token) {
    const { exp } = jwtDecode(token);

    const expAt = new Date(0);
    expAt.setUTCMilliseconds(exp * 1000);

    if (new Date(Date.now()) < new Date(expAt)) isLoggedIn = true;
  }

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('login', { replace: true });
    }
  }, [isLoggedIn, navigate]);

  return children;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthGuard;
